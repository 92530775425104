import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CrudView from 'components/Crud/CrudView';
import apiKeyMessages from './messages';
import hostMessages from './hostMessages';
import validityMessages from './validityMessages';
import baseStyles from '../../styles/app.scss';

class ApiKeyView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };
  render() {
    const modelQueryAttributes = [
      'id',
      'name',
      'description',
      'enabled',
      'validationInterval',
      'autoRenew',
      'secret',
      {
        hosts: ['id', 'name', 'description', 'enabled'],
        parentColumn: 'apiKeyId',
      },
      {
        validities: [
          'id',
          'start',
          'end',
          'enabled',
          {
            license: ['id'],
            parentColumn: 'licenseId',
            isSelect: true,
          },
        ],
        parentColumn: 'apiKeyId',
      },
      {
        license: ['id'],
        parentColumn: 'licenseId',
        isSelect: true,
      },
      {
        billingData: ['id'],
        parentColumn: 'billingDataId',
        isSelect: true,
      },
    ];

    const viewAttributes = {
      name: { type: 'text', required: true },
      description: { type: 'text', required: false },
      enabled: { type: 'boolean' },
      validationInterval: { type: 'int', required: true },
      autoRenew: { type: 'boolean' },
      secret: { type: 'text', readOnly: true },
      license: {
        type: 'selectModel',
        query: 'licenses',
        columnName: 'licenseId',
        viewAttribute: ['name', 'description', 'enabled', 'renewable'],
        attributeTypes: ['string', 'string', 'boolean', 'boolean'],
        required: true,
      },
      billingData: {
        type: 'selectModel',
        query: 'billingDatas',
        columnName: 'billingDataId',
        viewAttribute: [
          { customer: ['name'] },
          'contactName',
          'street',
          'number',
          'zip',
          'city',
          'country',
          'vatNumber',
          'vat',
        ],
        required: true,
      },
      host: {
        type: 'model',
        viewAttributes: {
          name: { type: 'text', required: true },
          description: { type: 'text', required: true },
          enabled: { type: 'boolean' },
        },
        queryAttribute: 'hosts',
      },
      validity: {
        type: 'model',
        viewAttributes: {
          start: { type: 'date', required: true },
          end: { type: 'date', required: true, readOnly: true },
          license: {
            type: 'selectModel',
            query: 'licenses',
            columnName: 'licenseId',
            viewAttribute: ['name', 'description', 'enabled', 'renewable'],
            required: true,
            defaultSelector: {
              formPrefix: 'Api Key',
              field: 'licenseId',
              target: 'licenseId',
            },
          },
          enabled: { type: 'boolean', default: true },
        },
        queryAttribute: 'validities',
      },
    };

    const messages = {
      messageList: apiKeyMessages,
      host: { messageList: hostMessages },
      validity: { messageList: validityMessages },
    };
    return (
      <CrudView
        headerName="Api Key"
        modelName="apiKey"
        modelQueryAttributes={modelQueryAttributes}
        messages={messages}
        viewAttributes={viewAttributes}
        match={this.props.match}
      />
    );
  }
}

export default withStyles(baseStyles)(ApiKeyView);
