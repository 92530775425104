exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2UoK-,._3acYs{margin:5px}._2UoK-{color:#aaa}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"tabTitle": "_3acYs",
	"tabTitleGrey": "_2UoK-"
};