import { defineMessages } from 'react-intl';

const messages = defineMessages({
  name: {
    id: 'licenseProduct.id',
    defaultMessage: 'ID',
    description: 'Label for id in licenseProduct',
  },
  product: {
    id: 'licenseProduct.product',
    defaultMessage: 'Product',
    description: 'Label for product in licenseProduct',
  },
});

export default messages;
