import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import moment from 'moment';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  defineMessages,
} from 'react-intl';
import { graphql, compose } from 'react-apollo';
import { Modal } from 'react-bootstrap';
import Table from 'components/Table';

const DEFAULT_PAGE_SIZE = 10;

const messages = defineMessages({
  close: {
    id: 'validationLogsModal.close',
    defaultMessage: 'Close',
    description: 'Close Button label in Validation Logs Modal',
  },
  modalHeader: {
    id: 'validationLogsModal.modalHeader',
    defaultMessage: 'Validation Logs',
    description: 'Header label for Validation Logs Modal',
  },
  showValidationLogs: {
    id: 'validationLogsModal.showValidationLogs',
    defaultMessage: 'Validation Logs',
    description: 'Label for show validation logs button in apiKey table',
  },
  validationDate: {
    id: 'validationLogsModal.validationDate',
    defaultMessage: 'Validation Date',
    description:
      'Label for Validation Date text in validation logs modal table',
  },
  result: {
    id: 'validationLogsModal.result',
    defaultMessage: 'Result',
    description: 'Label for Result text in validation logs modal table',
  },
  version: {
    id: 'validationLogsModal.version',
    defaultMessage: 'Version',
    description: 'Label for Version in validation logs modal table',
  },
  key: {
    id: 'validationLogsModal.key',
    defaultMessage: 'Product Key',
    description: 'Label for Product key in validation logs modal table',
  },
  noLogs: {
    id: 'validationLogsModal.noLogs',
    defaultMessage: 'No Logs stored',
    description: 'Label if no logs are present in validation logs modal table',
  },
});

const validationLogsQuery = gql`
  query validationLogsForApiKey($apiKeyId: String!) {
    validationLogsForApiKey(apiKeyId: $apiKeyId) {
      id
      type
      createdAt
      productVersion {
        id
        version
        product {
          id
          key
        }
      }
    }
  }
`;

class ValidationLogsModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    apiKey: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    validationLogs: PropTypes.shape({
      loading: PropTypes.bool,
      validationLogsForApiKey: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          createdAt: PropTypes.string,
          type: PropTypes.string,
          productVersion: PropTypes.shape({
            id: PropTypes.string,
            version: PropTypes.string,
            product: PropTypes.shape({
              id: PropTypes.string,
              key: PropTypes.string,
            }),
          }),
        }),
      ),
    }).isRequired,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.getColumns = this.getColumns.bind(this);
  }

  getColumns() {
    const { intl } = this.props;
    return [
      {
        Header: intl.formatMessage(messages.validationDate),
        accessor: 'createdAt',
        sortMethod: (a, b) => moment(a).diff(moment(b)),
      },
      {
        Header: intl.formatMessage(messages.version),
        accessor: 'version',
      },
      {
        Header: intl.formatMessage(messages.key),
        accessor: 'key',
      },
      {
        Header: intl.formatMessage(messages.result),
        accessor: 'type',
      },
    ];
  }

  render() {
    const { isOpen, validationLogs, onClose } = this.props;
    let logs = [];
    if (isOpen && !validationLogs.loading) {
      logs = validationLogs.validationLogsForApiKey.map(log => {
        const version = log.productVersion ? log.productVersion.version : null;
        const productVersion = log.productVersion ? log.productVersion : null;
        return {
          createdAt: log.createdAt,
          type: log.type,
          version,
          key: productVersion ? productVersion.product.key : '',
        };
      });
    }

    return (
      <div>
        <Modal show={isOpen} backdrop>
          <Modal.Body>
            <div>
              <div>
                <div>
                  <h1>
                    <FormattedMessage {...messages.modalHeader} />
                  </h1>
                  <h3>{this.props.apiKey.name}</h3>
                </div>
              </div>
              <div style={{ marginBottom: '5px' }}>
                {logs && logs.length > 0 && (
                  <Table
                    className="-striped"
                    data={logs}
                    defaultPageSize={DEFAULT_PAGE_SIZE}
                    minRows={0}
                    columns={this.getColumns()}
                  />
                )}
                {logs.length === 0 && (
                  <strong>
                    <FormattedMessage {...messages.noLogs} />
                  </strong>
                )}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  <FormattedMessage {...messages.close} />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default compose(
  graphql(validationLogsQuery, {
    name: 'validationLogs',
    options: ({ apiKey }) => ({
      variables: {
        apiKeyId: apiKey.id,
      },
    }),
    skip: props => !props.isOpen,
  }),
)(injectIntl(ValidationLogsModal));
