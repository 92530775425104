exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3JWRa{width:100%;height:100%;margin:0;background-color:#00233c}._3JWRa ._3JhXK{width:100%;padding-bottom:15px}._3JWRa ._3JhXK .qlUVb{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;vertical-align:middle}._3JWRa ._3JhXK .qlUVb h2{margin-top:0;font-family:Gotham SSm A,Gotham SSm B,HelveticaNeue-Light,sans-serif;font-size:22px;font-weight:400;color:#eefaff}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"globalHitRateLineChart": "_3JWRa",
	"topbar": "_3JhXK",
	"header": "qlUVb"
};