import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CrudView from 'components/Crud/CrudView';
import licenseMessages from './messages';
import licenseProductMessages from '../LicenseProduct/messages';
import baseStyles from '../../styles/app.scss';

class LicenseView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };
  render() {
    const modelQueryAttributes = [
      'id',
      'name',
      'description',
      'price',
      'paymentInterval',
      'duration',
      'credits',
      'limit',
      'data',
      'enabled',
      'visible',
      'renewable',
      {
        products: ['id'],
        parentColumn: 'licenseId',
        joinTable: 'licenseProduct',
        joinAttributes: ['id'],
        isSelect: true,
      },
    ];

    const viewAttributes = {
      name: { type: 'text', required: true },
      description: { type: 'text', required: false },
      price: { type: 'float', required: true },
      paymentInterval: { type: 'text', required: true },
      duration: { type: 'text', required: true },
      credits: { type: 'int', required: false },
      limit: { type: 'int', required: false },
      data: { type: 'object', required: true },
      enabled: { type: 'boolean', required: true },
      visible: { type: 'boolean', required: true },
      renewable: { type: 'boolean', required: true },
      licenseProduct: {
        type: 'model',
        viewAttributes: {
          product: {
            type: 'selectModel',
            query: 'products',
            columnName: 'productId',
            viewAttribute: 'name',
            required: true,
          },
        },
      },
    };
    const messages = {
      messageList: licenseMessages,
      licenseProduct: { messageList: licenseProductMessages },
    };
    return (
      <CrudView
        headerName="License"
        modelName="license"
        modelQueryAttributes={modelQueryAttributes}
        messages={messages}
        viewAttributes={viewAttributes}
        match={this.props.match}
      />
    );
  }
}

export default withStyles(baseStyles)(LicenseView);
