import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CrudTable from 'components/Crud/CrudTable';
import UserTokenButton from './UserTokenButton';
import messages from './messages';
import baseStyles from '../../styles/app.scss';

class UserTable extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  };
  render() {
    const modelQueryAttributes = ['id', 'username'];
    const modelQueryArguments = {
      attributes: modelQueryAttributes,
    };
    const tableAttributes = {
      username: { width: 400 },
    };
    const actionComponents = [row => <UserTokenButton user={row} />];
    return (
      <CrudTable
        headerName="Users"
        modelName="users"
        modelQueryArguments={modelQueryArguments}
        messages={messages}
        tableAttributes={tableAttributes}
        match={this.props.match}
        actionComponents={actionComponents}
        actionComponentsWidth={200}
      />
    );
  }
}

export default withStyles(baseStyles)(UserTable);
