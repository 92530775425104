import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';

import GlobalHitRateLineChart from './GlobalHitRateLineChart';

// eslint-disable-next-line css-modules/no-unused-class
import s from './AnalyticsDashboard.scss';

const REFETCH_INTERVAL = 1000 * 60 * 10;

const statisticVisualizationQuery = gql`
  query statisticVisualization($filter: Filter!) {
    statisticVisualization(filter: $filter) {
      name
      type
      unit
      statisticsValues {
        label
        color
        data {
          date
          value
        }
      }
    }
  }
`;
class LineChartData extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    defaultFilter: PropTypes.shape({
      statisticName: PropTypes.string.isRequired,
      granularity: PropTypes.string.isRequired,
    }).isRequired,
    generateFilter: PropTypes.func.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      variables: PropTypes.shape({
        filter: PropTypes.object.isRequired,
      }).isRequired,
      statisticVisualization: PropTypes.any.isRequired,
      startPolling: PropTypes.func.isRequired,
      stopPolling: PropTypes.func.isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.onFilterChanged = this.onFilterChanged.bind(this);

    this.state = {
      granularity: props.defaultFilter.granularity,
    };
  }

  async onFilterChanged(filter) {
    const { startPolling, stopPolling, refetch } = this.props.data;
    stopPolling();
    await refetch({ filter });
    startPolling(REFETCH_INTERVAL);
    this.setState({ granularity: filter.granularity });
  }

  render() {
    const { generateFilter, data } = this.props;
    return (
      <>
        {data && data.statisticVisualization && (
          <div className={s.widget}>
            <GlobalHitRateLineChart
              data={data.statisticVisualization}
              handleFilterChange={this.onFilterChanged}
              generateFilter={generateFilter}
              defaultGranularity={this.state.granularity}
            />
          </div>
        )}
      </>
    );
  }
}

export default compose(
  graphql(statisticVisualizationQuery, {
    name: 'data',
    options: props => ({
      pollInterval: REFETCH_INTERVAL,
      variables: { filter: props.defaultFilter },
    }),
  }),
)(withStyles(s)(LineChartData));
