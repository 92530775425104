import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Line, defaults } from 'react-chartjs-2';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import moment from 'moment';

import s from './GlobalHitRateLineChart.scss';
import { chartGranularity } from './constants';
import {
  HOURLY_DATE_FORMAT,
  STATISTICS_VALUE_LABEL_UNIT_TYPES,
} from '../../constants';

defaults.global.defaultFontColor = '#80929e'; // $danube-medium-gray

class GlobalHitRateLineChart extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    generateFilter: PropTypes.func.isRequired,
    defaultGranularity: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      formattedData: {
        labels: [],
        datasets: [],
      },
    };
    this.granularity = props.defaultGranularity;

    this.formatData = this.formatData.bind(this);
    this.onTimeGranularityChanged = this.onTimeGranularityChanged.bind(this);
  }

  componentDidMount() {
    this.formatData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.formatData();
    }
  }

  onTimeGranularityChanged(timeGranularity) {
    const { handleFilterChange, generateFilter, data } = this.props;
    this.granularity = timeGranularity;
    const filter = generateFilter(data.name, timeGranularity);
    handleFilterChange(filter);
  }

  formatData() {
    const { data } = this.props;
    const formattedData = {
      labels: [],
      datasets: [],
    };

    // error handling in case we don't have props
    if (!data.statisticsValues) {
      this.setState({
        formattedData: {
          labels: [],
          datasets: [],
        },
      });
      return;
    }

    data.statisticsValues.forEach(statisticsValue => {
      const lineColor = statisticsValue.color;
      const dataset = {
        label: statisticsValue.label,
        fill: false,
        lineTension: 0.3,
        borderWidth: 2,
        backgroundColor: lineColor,
        borderColor: lineColor,
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 1,
        pointHoverRadius: 10,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        data: [],
      };

      if (statisticsValue.data) {
        if (formattedData.labels.length === 0) {
          // all datasets share the same labels, run only once
          formattedData.labels = statisticsValue.data.map(
            dataEntry => dataEntry.date,
          );
        }
        dataset.data = statisticsValue.data.map(dataEntry => dataEntry.value);
      }
      formattedData.datasets.push(dataset);
    });

    if (formattedData.labels.length === 0) {
      const { selectedGranularity } = this.state;
      const currentDate = moment();
      let startDate;
      switch (selectedGranularity) {
        case chartGranularity.HOURS:
          startDate = currentDate.clone().subtract(24, 'hours');
          while (currentDate > startDate) {
            formattedData.labels.push(startDate.format(HOURLY_DATE_FORMAT));
            startDate.add(1, 'hours');
          }
          formattedData.labels.push(startDate.format(HOURLY_DATE_FORMAT));
          break;
        case chartGranularity.DAY:
          startDate = currentDate.clone().subtract(1, 'weeks');
          while (currentDate > startDate) {
            formattedData.labels.push(startDate.format('DD.MM.YYYY'));
            startDate.add(1, 'days');
          }
          formattedData.labels.push(startDate.format('DD.MM.YYYY'));
          break;
        case chartGranularity.MONTH:
          startDate = currentDate.clone().subtract(12, 'months');
          while (currentDate > startDate) {
            formattedData.labels.push(startDate.format('MMMM'));
            startDate.add(1, 'months');
          }
          formattedData.labels.push(startDate.format('MMMM'));
          break;
        default:
          break;
      }
    }

    this.setState({ formattedData });
  }

  render() {
    const { data } = this.props;
    const { formattedData } = this.state;
    const chartOptions = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: (val, index) => {
                if (index % 2 !== 0) {
                  return '';
                }
                if (data.unit === STATISTICS_VALUE_LABEL_UNIT_TYPES.EURO) {
                  return `${val}€`;
                }
                return val > 9999 ? `${val / 1000}K` : val;
              },
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              callback: val =>
                this.granularity === chartGranularity.HOURS
                  ? moment(val)
                      .add(1, 'hour')
                      .startOf('hour')
                      .format('HH:mm')
                  : val,
            },
          },
        ],
      },
      responsive: true,
      legend: {
        display: false,
        position: 'top',
        labels: {
          fontSize: 20,
        },
      },
    };
    return (
      <div className={s.globalHitRateLineChart}>
        <div className={s.topbar}>
          <div className={s.header}>
            <h2>{data.name}</h2>
            <div>
              <ToggleButtonGroup
                type="radio"
                onChange={this.onTimeGranularityChanged}
                value={this.granularity}
                name="options"
              >
                <ToggleButton
                  className="btn btn-dark-outline"
                  value={chartGranularity.HOURS}
                >
                  DAY
                </ToggleButton>
                <ToggleButton
                  className="btn btn-dark-outline"
                  value={chartGranularity.DAY}
                >
                  WEEK
                </ToggleButton>
                <ToggleButton
                  className="btn btn-dark-outline"
                  value={chartGranularity.MONTH}
                >
                  YEAR
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>
        <Line data={formattedData} options={chartOptions} />
      </div>
    );
  }
}

export default withStyles(s)(GlobalHitRateLineChart);
