import moment from 'moment';
import { DATE_FORMAT } from '../constants';

const DB_DATE_FORMAT = 'ddd MMM D YYYY HH:mm:ss ZZ';

const getCurrentDayBoundaries = () => {
  const start = moment()
    .utc()
    .startOf('day');
  const end = moment()
    .utc()
    .endOf('day');

  return { start, end };
};

const getCurrentWeekBoundaries = () => {
  const start = moment().startOf('isoWeek');
  const end = moment().endOf('isoWeek');

  return { start, end };
};

const getMonthBoundariesByStartDay = ({ date }) => {
  let startDate;
  let endDate;
  const dateFormat = DATE_FORMAT;
  const startDay = moment(date).date();
  if (startDay <= moment().date()) {
    // this and next month
    startDate = moment()
      .set('date', startDay)
      .format(dateFormat);
    endDate = moment()
      .set('date', startDay)
      .add(1, 'M')
      .format(dateFormat);
  } else {
    // last and this month
    startDate = moment()
      .set('date', startDay)
      .subtract(1, 'M')
      .format(dateFormat);
    endDate = moment()
      .set('date', startDay)
      .format(dateFormat);
  }
  return {
    startDate,
    endDate,
  };
};

const getCurrentMonthBoundaries = () => {
  const start = moment()
    .startOf('month')
    .toDate();
  const end = moment()
    .endOf('month')
    .toDate();

  return { start, end };
};

const getWeekBoundariesByNum = ({ num }) => {
  if (!num) return null;
  const start = moment()
    .week(num)
    .startOf('isoWeek');
  const end = moment()
    .week(num)
    .endOf('isoWeek');

  return { start, end };
};

const getMonthBoundariesByNum = ({ num, year }) => {
  if (!num && num !== 0) return null;
  const start = moment()
    .year(year)
    .month(num)
    .startOf('month');
  const end = moment()
    .year(year)
    .month(num)
    .endOf('month');

  return { start, end };
};

const getMonthBoundariesByDate = ({ date, format = 'DD.MM.YYYY' }) => {
  const currentDate = moment(date, format);
  const start = moment(currentDate)
    .startOf('month')
    .toDate();
  const end = moment(currentDate)
    .endOf('month')
    .toDate();

  return { start, end };
};

const getTimeSlots = ({ interval = 15 } = {}) => {
  const start = moment().startOf('day');
  const end = moment().endOf('day');
  const timeSlots = [];
  for (let i = start; i.isBefore(end); i.add(interval, 'minutes')) {
    timeSlots.push(i.format('HH:mm'));
  }
  return timeSlots;
};

const calculateInitialTimeValue = ({ interval = 15 } = {}) => {
  const now = moment();
  // round down to interval
  const diffUp = interval - (now.minute() % interval);
  const diffDown = now.minute() % interval;
  let roundedTime;
  if (diffUp < diffDown) {
    roundedTime = now.add(diffUp, 'minutes');
  } else {
    roundedTime = now.subtract(diffDown, 'minutes');
  }
  return roundedTime;
};

export {
  DB_DATE_FORMAT,
  getCurrentDayBoundaries,
  getCurrentWeekBoundaries,
  getCurrentMonthBoundaries,
  getWeekBoundariesByNum,
  getMonthBoundariesByNum,
  getMonthBoundariesByDate,
  getTimeSlots,
  calculateInitialTimeValue,
  getMonthBoundariesByStartDay,
};
