import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from '../../styles/app.scss';
import s from './Layout.css';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return [
      <div
        key="app-content"
        style={{
          minHeight: '100vh',
          margin: '0 7.5%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {this.props.children}
      </div>,
    ];
  }
}

export default withStyles(baseStyles, s)(Layout);
