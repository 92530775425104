import React from 'react';
import PropTypes from 'prop-types';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import RouteBundler from 'components/RouteBundler';
import Dashboard from 'components/Dashboard';
import User from 'components/User';
import Role from 'components/Role';
import Product from 'components/Product';
import License from 'components/License';
import Customer from 'components/Customer';
import ApiKey from 'components/ApiKey';
import Statistics from 'components/Statistics';
import roles from 'core/roles';

/* eslint-disable prettier/prettier */
const adminRoutes = [
  { path: '', component: Dashboard, exact: true },
  { path: 'dashboard', component: Dashboard, exact: true },
  { path: 'user', component: User },
  { path: 'role', component: Role },
  { path: 'product', component: Product },
  { path: 'license', component: License },
  { path: 'customer', component: Customer },
  { path: 'apikey', component: ApiKey },
];

const statisticsRoutesStandalone = [
  { path: '', component: Statistics, exact: true },
];

const statisticsRoutesCombined = [
  { path: 'statistics', component: Statistics },
];
/* eslint-enable prettier/prettier */

class Admin extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/prop-types
      roles: this.props.me.roles.map(ele => ele.name),
      routes: [],
    };

    this.determineRoutes = this.determineRoutes.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  determineRoutes() {
    const tempRoutes = [];
    // eslint-disable-next-line no-unused-expressions
    this.state.roles.includes(roles.ADMIN)
      ? tempRoutes.push(...adminRoutes)
      : null;
    // eslint-disable-next-line no-unused-expressions
    this.state.roles.includes(roles.ADMIN) &&
    this.state.roles.includes(roles.VIEW_DASHBOARD_STATISTICS)
      ? tempRoutes.push(...adminRoutes, ...statisticsRoutesCombined)
      : null;

    // eslint-disable-next-line no-unused-expressions
    this.state.roles.includes(roles.VIEW_DASHBOARD_STATISTICS) &&
    !this.state.roles.includes(roles.ADMIN)
      ? tempRoutes.push(...statisticsRoutesStandalone)
      : null;

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ routes: tempRoutes });
  }

  componentDidMount() {
    this.determineRoutes();
  }

  render() {
    return (
      <RouteBundler
        prefix={`${this.props.match.path}/`}
        routes={this.state.routes}
        me={this.props.me}
      />
    );
  }
}

export default AuthenticationWrapper(Admin);
