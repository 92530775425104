import { defineMessages } from 'react-intl';

const messages = defineMessages({
  start: {
    id: 'validity.start',
    defaultMessage: 'Start',
    description: 'Label for start in validity',
  },
  end: {
    id: 'validity.end',
    defaultMessage: 'End',
    description: 'Label for end in validity',
  },
  license: {
    id: 'validity.license',
    defaultMessage: 'License',
    description: 'Label for license in validity',
  },
  enabled: {
    id: 'validity.enabled',
    defaultMessage: 'Enabled',
    description: 'Label for enabled in validity',
  },
});

export default messages;
