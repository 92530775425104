import React from 'react';
import PropTypes from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';

import render from '../ReduxForm/renderField';

const messages = defineMessages({
  selectServers: {
    id: 'dashboard.selectServers',
    defaultMessage: 'Select servers (empty = all servers)',
    description: 'Text for server selection',
  },
  filter: {
    id: 'dashboard.filter',
    defaultMessage: 'Filter',
    description: 'Text for filter label',
  },
});

class ServerSelectionForm extends React.Component {
  static propTypes = {
    servers: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    const { servers, errors, intl, handleSubmit } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <Row>
              <Col xs={12} md={8} lg={6}>
                <Field
                  id="server"
                  name="server"
                  placeholder={intl.formatMessage(messages.selectServers)}
                  component={render.renderSelectAlt}
                  isMulti
                  options={servers.map(server => ({
                    value: server.id,
                    label: server.name,
                  }))}
                />
              </Col>
              <Col xs={12} md={4} lg={6}>
                <div className="btn-group" style={{ display: 'inline-block' }}>
                  <button type="submit" className="btn btn-primary">
                    <FormattedMessage {...messages.filter} />
                  </button>
                </div>
              </Col>
            </Row>
            <div>
              {errors.length === 0 ? null : (
                <ul style={{ padding: '0', listStyleType: 'none' }}>
                  {errors.map(err => (
                    <li key={err} className="bg-danger">
                      {err}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'serverSelectionForm',
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(ServerSelectionForm));
