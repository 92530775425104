import { defineMessages } from 'react-intl';

const messages = defineMessages({
  name: {
    id: 'productRole.id',
    defaultMessage: 'ID',
    description: 'Label for id in productRole',
  },
  product: {
    id: 'productRole.product',
    defaultMessage: 'Product',
    description: 'Label for product in productRole',
  },
});

export default messages;
