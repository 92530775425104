import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { FormattedMessage, defineMessages } from 'react-intl';
import copy from 'copy-to-clipboard';

const messages = defineMessages({
  userToken: {
    id: 'user.token',
    defaultMessage: 'User Token (jwt)',
    description: 'Label for jwt in user',
  },
  copyToClipboard: {
    id: 'user.copyToClipboard',
    defaultMessage: 'Copy to Clipboard',
    description: 'Label for copy to clipboard button in user',
  },
});

const getUserToken = gql`
  query userToken($id: ID!) {
    userToken(id: $id)
  }
`;

class UserTokenButton extends React.Component {
  static contextTypes = {
    client: PropTypes.object,
  };

  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleCopy = this.handleCopy.bind(this);
  }

  async handleCopy() {
    const { user } = this.props;
    const queryResult = await this.context.client.query({
      query: getUserToken,
      variables: {
        id: user.id,
      },
      fetchPolicy: 'network-only',
    });
    copy(queryResult.data.userToken);
  }

  render() {
    const { user } = this.props;
    return (
      <div>
        {user.id && (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleCopy}
            >
              <FormattedMessage {...messages.copyToClipboard} />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default UserTokenButton;
