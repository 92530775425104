import React from 'react';
import { Row, Col } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// eslint-disable-next-line css-modules/no-unused-class
import s from './AnalyticsDashboard.scss';
import LineChartData from './LineChartData';
// import { HOURLY_DATE_FORMAT } from '../../constants';
import { chartGranularity } from './constants';

const generateFilter = (filterName, granularity) => {
  const filter = {
    statisticName: filterName,
    granularity,
  };
  return filter;
};

class AnalyticsDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.defaultFilter = [
      generateFilter('API Usages', chartGranularity.HOURS),
      generateFilter('Profit', chartGranularity.HOURS),
      generateFilter('API Usages', chartGranularity.MONTH),
      generateFilter('Profit', chartGranularity.MONTH),
    ];
  }

  render() {
    return (
      <div className={s.dashboard}>
        <Row style={{ margin: 0 }}>
          {this.defaultFilter.map(filter => (
            <Col md={6}>
              <LineChartData
                defaultFilter={filter}
                generateFilter={generateFilter}
              />
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

export default withStyles(s)(AnalyticsDashboard);
