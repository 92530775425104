import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dashboard: {
    id: 'sidebar.dashboard',
    defaultMessage: 'Dashboard',
    description: 'Label for dashboard link in sidebar',
  },
  user: {
    id: 'sidebar.user',
    defaultMessage: 'User',
    description: 'Label for user link in sidebar',
  },
  role: {
    id: 'sidebar.role',
    defaultMessage: 'Role',
    description: 'Label for role link in sidebar',
  },
  product: {
    id: 'sidebar.product',
    defaultMessage: 'Product',
    description: 'Label for product link in sidebar',
  },
  license: {
    id: 'sidebar.license',
    defaultMessage: 'License',
    description: 'Label for license link in sidebar',
  },
  customer: {
    id: 'sidebar.customer',
    defaultMessage: 'Customer',
    description: 'Label for customer link in sidebar',
  },
  apikey: {
    id: 'sidebar.apikey',
    defaultMessage: 'API Key',
    description: 'Label for apikey link in sidebar',
  },
  statistics: {
    id: 'sidebar.statistics',
    defaultMessage: 'View Statistics',
    description: 'Label for statistics link in sidebar',
  },
});

export default messages;
