import { defineMessages } from 'react-intl';

const messages = defineMessages({
  id: {
    id: 'license.id',
    defaultMessage: 'Id',
    description: 'Label for id in license',
  },
  name: {
    id: 'license.name',
    defaultMessage: 'Name',
    description: 'Label for name in license',
  },
  description: {
    id: 'license.description',
    defaultMessage: 'Description',
    description: 'Label for description in license',
  },
  price: {
    id: 'license.price',
    defaultMessage: 'Price',
    description: 'Label for price in license',
  },
  paymentInterval: {
    id: 'license.paymentInterval',
    defaultMessage: 'Payment Interval',
    description: 'Label for paymentInterval in license',
  },
  duration: {
    id: 'license.duration',
    defaultMessage: 'Duration',
    description: 'Label for duration in license',
  },
  credits: {
    id: 'license.credits',
    defaultMessage: 'Credits',
    description: 'Label for credits in license',
  },
  limit: {
    id: 'license.limit',
    defaultMessage: 'Limit',
    description: 'Label for limit in license',
  },
  data: {
    id: 'license.data',
    defaultMessage: 'Data',
    description: 'Label for data in license',
  },
  enabled: {
    id: 'license.enabled',
    defaultMessage: 'Enabled',
    description: 'Label for enabled in license',
  },
  visible: {
    id: 'license.visible',
    defaultMessage: 'Visible',
    description: 'Label for visible in license',
  },
  product: {
    id: 'license.product',
    defaultMessage: 'Products',
    description: 'Label for products in license',
  },
  renewable: {
    id: 'license.renewable',
    defaultMessage: 'Renewable',
    description: 'Label for renewable in license',
  },
});

export default messages;
