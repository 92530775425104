/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import FaEdit from 'react-icons/lib/fa/edit';
import FaPlusCircle from 'react-icons/lib/fa/plus-circle';
import FaCheck from 'react-icons/lib/fa/check';

import Loading from 'components/Loading';
import Table from 'components/Table';
import tableMessages from 'components/Table/messages';

import QueryGenerator from './QueryGenerator';

class CrudTable extends React.Component {
  static contextTypes = {
    client: PropTypes.object,
  };

  static propTypes = {
    headerName: PropTypes.string.isRequired,
    modelName: PropTypes.string.isRequired,
    modelQueryArguments: PropTypes.shape({
      attributes: PropTypes.array,
      argumentObject: PropTypes.object,
    }).isRequired,
    messages: PropTypes.object.isRequired,
    tableAttributes: PropTypes.object.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    actionComponents: PropTypes.array.isRequired,
    actionComponentsWidth: PropTypes.number,
  };

  static defaultProps = {
    actionComponentsWidth: 100,
  };

  static booleanValue(stringValue) {
    return (
      stringValue === 'true' || stringValue === '1' || stringValue === 'yes'
    );
  }

  constructor(props) {
    super(props);

    this.queryModel = this.queryModel.bind(this);
    this.createColumns = this.createColumns.bind(this);

    this.state = {
      initialized: false,
    };
  }

  componentWillMount() {
    this.queryModel();
  }

  filterColumn(filt, row, attribute) {
    let attributeValue = row._original[attribute]; // eslint-disable-line no-underscore-dangle
    const filterString = filt.value.toLowerCase();
    if (
      typeof attributeValue === 'object' &&
      'queryValue' in this.props.tableAttributes[attribute]
    ) {
      attributeValue = this.props.tableAttributes[attribute].queryValue(
        attributeValue,
      );
    }
    if (typeof attributeValue === 'string') {
      const name = attributeValue.toLowerCase();
      return name.indexOf(filterString) !== -1;
    } else if (typeof attributeValue === 'boolean') {
      if (attributeValue && CrudTable.booleanValue(filt.value)) {
        return true;
      } else if (!attributeValue && !CrudTable.booleanValue(filt.value)) {
        return true;
      }
    } else if (typeof attributeValue === 'object') {
      return (
        JSON.stringify(attributeValue)
          .toLowerCase()
          .indexOf(filterString) !== -1
      );
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  async resolveModel(entry) {
    const updatedEntry = entry;
    const { tableAttributes } = this.props;
    if (tableAttributes) {
      await Promise.all(
        Object.keys(tableAttributes).map(async key => {
          const tableAttributeObject = tableAttributes[key];
          if (tableAttributeObject.resolve) {
            updatedEntry[key] = await tableAttributeObject.resolve(entry);
          }
          return Promise.resolve();
        }),
      );
    }
    return updatedEntry;
  }

  async queryModel() {
    const {
      modelName,
      modelQueryArguments: { attributes, argumentObject },
    } = this.props;
    const modelQueryGraphql = QueryGenerator.getModelQuery({
      modelName,
      attributes,
      argumentObject,
    });
    const queryResult = await this.context.client.query({
      query: modelQueryGraphql,
      fetchPolicy: 'network-only',
    });
    this.modelQuery = queryResult.data[modelName] || [];
    // call all available resolvers for additional data queries
    this.modelQuery = await Promise.all(
      this.modelQuery.map(async entry => this.resolveModel(entry)),
    );
    this.setState({
      initialized: true,
    });
  }

  createColumns() {
    const {
      match: { path },
      messages,
      tableAttributes,
      actionComponents,
    } = this.props;

    const defaultCellWidth = 200;
    const columns = Object.keys(tableAttributes).map(attribute => {
      const tableAttributeObject = tableAttributes[attribute];
      let headerMessage = '';
      if ('title' in tableAttributeObject) {
        headerMessage = tableAttributeObject.title;
      } else {
        headerMessage = messages[attribute];
      }

      const columnObject = {
        id: attribute,
        Header: <FormattedMessage {...headerMessage} />,
        width: tableAttributeObject.width || defaultCellWidth,
        Cell: ({ original }) =>
          // custom data query defined
          CrudTable.renderColumnValue(
            original[attribute],
            tableAttributeObject,
          ),
        filterMethod: (filt, row) => this.filterColumn(filt, row, attribute),
      };
      return columnObject;
    });
    columns.push({
      Header: <FormattedMessage {...tableMessages.actions} />,
      width: this.props.actionComponentsWidth,
      Cell: ({ original }) => (
        <Row>
          <Col xs={1}>
            <Link to={`${path}${original.id}`}>
              <FaEdit />
            </Link>
          </Col>
          {actionComponents &&
            actionComponents.map(actionComponent => (
              <Col xs={3}>{actionComponent(original)}</Col>
            ))}
        </Row>
      ),
      Filter: () => null,
    });
    return columns;
  }

  static renderColumnValue(value, tableAttributeObject) {
    let parsedValue = value;
    // if special query rule is available to select subfield of object
    if ('queryValue' in tableAttributeObject) {
      parsedValue = tableAttributeObject.queryValue(value);
    }
    let renderedValue = parsedValue !== null ? `${parsedValue}` : '';
    const columnValueStyles = {};
    if (typeof parsedValue === 'boolean') {
      if (parsedValue) {
        renderedValue = <FaCheck />;
        columnValueStyles.textAlign = 'center';
      } else {
        renderedValue = '';
      }
    } else if (Array.isArray(parsedValue)) {
      return parsedValue.map(v => <div>{v}</div>);
    } else if (parsedValue && typeof parsedValue === 'object') {
      renderedValue = JSON.stringify(parsedValue);
    }
    return <div style={columnValueStyles}>{renderedValue}</div>;
  }

  render() {
    const {
      headerName,
      match: { url },
    } = this.props;

    const { initialized } = this.state;
    if (!initialized) return <Loading />;

    const columns = this.createColumns();
    return (
      <div>
        <h1>
          {headerName}
          <Link to={`${url}/new`}>
            <FaPlusCircle />
          </Link>
        </h1>
        <Table
          keyField="name"
          data={this.modelQuery}
          columns={columns}
          filterable
        />
      </div>
    );
  }
}

export default CrudTable;
