import React from 'react';
import PropTypes from 'prop-types';

import CrudTable from 'components/Crud/CrudTable';
import messages from './messages';
import ShowVersionsModalButton from './showVersionsModalButton';

class ProductTable extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  };
  render() {
    const modelQueryAttributes = [
      'id',
      'key',
      'name',
      'description',
      'isOnPremises',
      'productVersionLifespan',
      { licenses: ['id', 'name'] },
      { productVersions: ['id', 'start', 'version'] },
    ];
    const modelQueryArguments = {
      attributes: modelQueryAttributes,
    };
    const actionComponents = [
      row => <ShowVersionsModalButton product={row} key={`button_${row.id}`} />,
    ];
    const tableAttributes = {
      name: { width: 200 },
      key: { width: 100 },
      description: { width: 400 },
      isOnPremises: { width: 100 },
      productVersionLifespan: { width: 300 },
    };
    return (
      <CrudTable
        headerName="Products"
        modelName="products"
        modelQueryArguments={modelQueryArguments}
        messages={messages}
        tableAttributes={tableAttributes}
        match={this.props.match}
        actionComponents={actionComponents}
        actionComponentsWidth={150}
      />
    );
  }
}

export default ProductTable;
