import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  defineMessages,
} from 'react-intl';
import PropTypes from 'prop-types';
import ValidationLogsModal from './validationLogsModal';

const messages = defineMessages({
  showValidationLogs: {
    id: 'validationLogsModal.showValidationLogs',
    defaultMessage: 'Validation Logs',
    description: 'Label for show validation logs button in apiKey table',
  },
});

class ValidationLogsModalButton extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    apiKey: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.showValidationLogs = this.showValidationLogs.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  onClose() {
    this.setState({ isOpen: false });
  }

  async showValidationLogs() {
    this.setState({ isOpen: true });
  }

  render() {
    const { isOpen } = this.state;
    const { apiKey } = this.props;
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.showValidationLogs}
        >
          <FormattedMessage {...messages.showValidationLogs} />
        </button>
        <ValidationLogsModal
          isOpen={isOpen}
          apiKey={apiKey}
          onClose={this.onClose}
        />
      </div>
    );
  }
}

export default injectIntl(ValidationLogsModalButton);
