import { defineMessages } from 'react-intl';

const messages = defineMessages({
  username: {
    id: 'auth.username',
    defaultMessage: 'Username',
    description: 'Username field placeholder text',
  },
  password: {
    id: 'auth.password',
    defaultMessage: 'Password',
    description: 'Password field placeholder text in',
  },
  invalidCredentials: {
    id: 'auth.invalidCredentials',
    defaultMessage: 'No user in our system matches the given credentials.',
    description: 'Error message for invalid credentials in login component',
  },
  rememberMe: {
    id: 'login.rememberMe',
    defaultMessage: 'Remember Me',
    description: 'Remember me checkbox in login form',
  },
});

export default messages;
