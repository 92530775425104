import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  defineMessages,
} from 'react-intl';
import PropTypes from 'prop-types';
import VersionModal from './VersionModal';

const messages = defineMessages({
  showModal: {
    id: 'productVersionModal.showModal',
    defaultMessage: 'Show Versions',
    description: 'Label for show product versions in the product table',
  },
});

class showVersionModalButton extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      productVersionLifespan: PropTypes.string.isRequired,
      productVersions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          version: PropTypes.string.isRequired,
          start: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.showVersionModal = this.showVersionModal.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  onClose() {
    this.setState({ isOpen: false });
  }

  async showVersionModal() {
    this.setState({ isOpen: true });
  }

  render() {
    const { isOpen } = this.state;
    const { product } = this.props;
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.showVersionModal}
        >
          <FormattedMessage {...messages.showModal} />
        </button>
        <VersionModal
          isOpen={isOpen}
          productId={product.id}
          onClose={this.onClose}
        />
      </div>
    );
  }
}

export default injectIntl(showVersionModalButton);
