import _ from 'lodash';
import { STATISTICS_VALUE_UNIT_TYPES } from '../../constants';

const parseApiKeyStatisticField = ({ statistic, numeric = false }) => {
  if (
    statistic.statisticsValueUnit === STATISTICS_VALUE_UNIT_TYPES.PERCENTAGE &&
    _.isNumber(statistic.statisticsValue)
  ) {
    const numericValue = statistic.statisticsValue.toFixed(4) * 100;
    return numeric ? numericValue : `${numericValue}%`;
  }
  return statistic.statisticsValue;
};

const parseApiKeyStatisticFieldForValueName = (
  apiKeyStatisticsValues,
  valueName,
) => {
  let newValue = null;
  let found = false;
  apiKeyStatisticsValues.forEach(statistic => {
    if (found) return;
    if (statistic.statisticsValueName === valueName) {
      found = true;
      newValue = parseApiKeyStatisticField({ statistic });
    }
  });

  return newValue;
};

export { parseApiKeyStatisticField, parseApiKeyStatisticFieldForValueName };
