exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body{background-color:#2e4b5f}._3e0bF ._1Yr9L{margin:10px 0;padding:10px;background-color:#00233c;border-radius:5px;-webkit-box-shadow:0 5px 28px 0 rgba(0,0,0,.2);box-shadow:0 5px 28px 0 rgba(0,0,0,.2)}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"dashboard": "_3e0bF",
	"widget": "_1Yr9L"
};