import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Navbar, Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { compose } from 'react-apollo';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import logo from 'components/Auth/login/key.svg';
import { withLogout } from 'components/Auth/withLogout';
import s from './TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class

class TopNavigation extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      username: PropTypes.string.isRequired,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  };

  handleSelect(eventKey) {
    switch (eventKey) {
      case 2.1:
        this.props.logout();
        break;
      default:
    }
  }

  render() {
    const {
      me: { username },
    } = this.props;

    return (
      <Navbar
        className={s.navbar}
        fixedTop
        fluid
        collapseOnSelect
        onSelect={k => this.handleSelect(k)}
      >
        <Navbar.Header>
          <Navbar.Brand className={s.logoContainer}>
            <a href="/app/dashboard">
              <div className={`${s.logoIcon} ${s.hideMobile}`}>
                <img src={logo} alt="" />
                <span>Waltzing License</span>
              </div>
              <div className={`${s.logoIcon} ${s.showMobile}`}>
                <img src={logo} alt="" />
              </div>
            </a>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse className={s.navbarCollapse}>
          <Nav pullRight>
            <NavDropdown
              className={s.navDropDown}
              eventKey={2}
              title={username}
              id="nav-dropdown"
            >
              <MenuItem eventKey={2.1}>Logout</MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default compose(
  AuthenticationWrapper,
  withLogout,
  withStyles(s),
)(TopNavigation);
