import React from 'react';
import PropTypes from 'prop-types';
import { Line as LineChart } from 'react-chartjs-2';
import { STATISTICS_VALUE_UNIT_TYPES } from '../../../constants';
import { parseApiKeyStatisticField } from '../utils';

const styles = {
  graphContainer: {
    padding: '15px',
  },
};

class ApiKeyStatisticsLineChart extends React.Component {
  static propTypes = {
    statistics: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  constructor(props) {
    super(props);
    this.prepareChartData();
  }

  prepareChartData() {
    const { statistics } = this.props;
    const labels = [];
    let unit = STATISTICS_VALUE_UNIT_TYPES.ABSOLUTE;
    let dataName = 'Dataset';
    const data = [];

    for (let i = statistics.length - 1; i >= 0; i -= 1) {
      const statistic = statistics[i];
      if (statistic.statisticsValueUnit) {
        unit = statistic.statisticsValueUnit;
      }
      if (statistic.statisticsValueName) {
        dataName = statistic.statisticsValueName;
      }
      labels.push(statistic.date);
      const parsedValue = parseApiKeyStatisticField({
        statistic,
        numeric: true,
      });
      data.push(parsedValue);
    }
    const datasets = [
      {
        label: dataName,
        backgroundColor: 'rgba(204, 248, 240, 0.2)',
        borderColor: '#90d8ca',
        pointBorderColor: '#078a70',
        pointBackgroundColor: '#078a70',
        data,
      },
    ];

    const options = {
      tooltips: {
        callbacks: {
          label(tooltipItem, d) {
            const dataset = d.datasets[tooltipItem.datasetIndex];
            const currentValue = dataset.data[tooltipItem.index];
            if (unit === STATISTICS_VALUE_UNIT_TYPES.PERCENTAGE) {
              return `${currentValue}%`;
            } else if (unit === STATISTICS_VALUE_UNIT_TYPES.EURO) {
              return `${currentValue}€`;
            }
            return `${currentValue}`;
          },
          title(tooltipItem, d) {
            return d.labels[tooltipItem[0].index];
          },
        },
      },
    };

    this.state = {
      data: { labels, datasets },
      options,
    };
  }

  render() {
    return (
      <div style={styles.graphContainer}>
        <LineChart data={this.state.data} options={this.state.options} />
      </div>
    );
  }
}

export default ApiKeyStatisticsLineChart;
