import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

/* eslint-disable react/prop-types */
export const CustomSingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.controlLabel || props.data.label}
  </components.SingleValue>
);
/* eslint-enable react/prop-types */

const optionType = PropTypes.shape({
  value: PropTypes.any,
  label: PropTypes.string,
});

class Select_ extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string, // only on inital field set
        optionType,
        PropTypes.arrayOf(optionType),
      ]),
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape({
        id: PropTypes.string,
      }),
      warning: PropTypes.bool,
    }).isRequired,
    options: PropTypes.arrayOf(optionType).isRequired,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    noOptionsMessage: PropTypes.string,
  };

  static defaultProps = {
    placeholder: '',
    isDisabled: false,
    isMulti: false,
    isSearchable: true,
    noOptionsMessage: '',
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedOptions) {
    this.props.input.onChange(selectedOptions);
  }

  render() {
    const {
      className,
      input: { value },
      meta: { touched, error, warning },
      options,
      placeholder,
      isDisabled,
      isMulti,
      isSearchable,
      noOptionsMessage,
    } = this.props;

    return (
      <div>
        <Select
          className={className}
          components={{ SingleValue: CustomSingleValue }}
          value={value}
          onChange={this.handleChange}
          options={options}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isSearchable={isSearchable}
          noOptionsMessage={() => noOptionsMessage}
        />
        {touched &&
          ((error && <p className="bg-danger">{error}</p>) ||
            (warning && <p className="bg-warning">{warning}</p>))}
      </div>
    );
  }
}

export default Select_;
