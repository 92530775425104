import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CrudTable from 'components/Crud/CrudTable';
import messages from './messages';
import baseStyles from '../../styles/app.scss';

class LicenseTable extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  };
  render() {
    const modelQueryAttributes = [
      'id',
      'name',
      'description',
      'price',
      'duration',
      'credits',
      'limit',
      'data',
      'enabled',
      'visible',
      { products: ['id', 'key', 'name'] },
    ];
    const modelQueryArguments = {
      attributes: modelQueryAttributes,
    };
    const tableAttributes = {
      name: { width: 200 },
      description: { width: 300 },
      price: { width: 200 },
      credits: { width: 150 },
      limit: { width: 150 },
      enabled: { width: 100 },
      visible: { width: 100 },
    };

    return (
      <CrudTable
        headerName="Licenses"
        modelName="licenses"
        modelQueryArguments={modelQueryArguments}
        messages={messages}
        tableAttributes={tableAttributes}
        match={this.props.match}
      />
    );
  }
}

export default withStyles(baseStyles)(LicenseTable);
