import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import copy from 'copy-to-clipboard';

const messages = defineMessages({
  copyToClipboard: {
    id: 'user.copyToClipboard',
    defaultMessage: 'Copy Secret',
    description: 'Label for copy to clipboard button in user',
  },
});

class ApiKeySecretButton extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    apiKey: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleCopy = this.handleCopy.bind(this);
  }

  async handleCopy() {
    const { apiKey } = this.props;
    copy(apiKey.secret);
  }

  render() {
    const { apiKey } = this.props;
    return (
      <div>
        {apiKey.id && (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleCopy}
            >
              <FormattedMessage {...messages.copyToClipboard} />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default ApiKeySecretButton;
