import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  defineMessages,
} from 'react-intl';
import { graphql, compose } from 'react-apollo';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Table from 'components/Table';
import gql from 'graphql-tag';

const messages = defineMessages({
  close: {
    id: 'productVersionModal.close',
    defaultMessage: 'Close',
    description: 'Close Button label in Product Versions Modal',
  },
  modalHeader: {
    id: 'productVersionModal.modalHeader',
    defaultMessage: 'Product Versions',
    description: 'Header label for Product Versions Modal',
  },
  version: {
    id: 'productVersionModal.version',
    defaultMessage: 'Version',
    description: 'Label for Version text in product version modal table',
  },
  start: {
    id: 'productVersionModal.start',
    defaultMessage: 'Start',
    description: 'Label for Start date in product version modal table',
  },
  end: {
    id: 'productVersionModal.end',
    defaultMessage: 'End',
    description: 'Label for End date in product version modal table',
  },
  noEntries: {
    id: 'productVersionModal.noEntries',
    defaultMessage: 'No product versions created yet',
    description: 'Label if no logs are present in product version modal table',
  },
});

const DATE_FORMAT = 'lll';

const getProduct = gql`
  query product($id: ID!) {
    product(id: $id) {
      name
      key
      productVersionLifespan
      productVersions {
        version
        start
      }
    }
  }
`;

class VersionModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    product: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        productVersionLifespan: PropTypes.string.isRequired,
        productVersions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            version: PropTypes.string.isRequired,
            start: PropTypes.string.isRequired,
          }).isRequired,
        ),
      }).isRequired,
    }).isRequired,
    productId: PropTypes.string.isRequired,
  };

  static filterColumn(filter, row, key) {
    // eslint-disable-next-line no-underscore-dangle
    return row._original[key]
      .toLowerCase()
      .includes(filter.value.toLowerCase());
  }

  getColumns() {
    const { intl } = this.props;
    return [
      {
        Header: intl.formatMessage(messages.version),
        accessor: 'version',
        filterMethod: (filter, row) =>
          VersionModal.filterColumn(filter, row, 'version'),
      },
      {
        Header: intl.formatMessage(messages.start),
        accessor: 'start',
        filterMethod: (filter, row) =>
          VersionModal.filterColumn(filter, row, 'start'),
        sortMethod: (a, b) => moment(a).diff(moment(b)),
      },
      {
        Header: intl.formatMessage(messages.end),
        accessor: 'end',
        filterMethod: (filter, row) =>
          VersionModal.filterColumn(filter, row, 'end'),
        sortMethod: (a, b) => moment(a).diff(moment(b)),
      },
    ];
  }

  render() {
    if (!this.props.product || this.props.product.loading) {
      return <div />;
    }
    const {
      product: { name, key, productVersionLifespan, productVersions },
    } = this.props.product;
    const { isOpen } = this.props;
    const noEntries = !productVersions || productVersions.length === 0;
    let versionEntries = null;
    if (noEntries) {
      versionEntries = (
        <strong>
          {' '}
          <FormattedMessage {...messages.noEntries} />
          <br />
        </strong>
      );
    } else {
      versionEntries = productVersions.map(productVersion => ({
        version: productVersion.version,
        start: moment(productVersion.start).format(DATE_FORMAT),
        end: moment(productVersion.start)
          .add(...productVersionLifespan.split(' '))
          .format(DATE_FORMAT),
      }));
    }
    return (
      <div>
        <Modal show={isOpen} backdrop>
          <Modal.Body>
            <div>
              <div>
                <div>
                  <h1>
                    <FormattedMessage {...messages.modalHeader} />
                  </h1>
                  <h3>{`${name} (${key})`}</h3>
                </div>
              </div>
              <div style={{ marginBottom: '5px' }}>
                {noEntries ? (
                  <div>
                    <br />
                    {versionEntries}
                    <br />
                  </div>
                ) : (
                  <Table
                    className="-striped"
                    data={versionEntries}
                    defaultPageSize={300}
                    minRows={0}
                    columns={this.getColumns()}
                    filterable
                  />
                )}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    this.props.onClose();
                  }}
                >
                  <FormattedMessage {...messages.close} />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default compose(
  graphql(getProduct, {
    name: 'product',
    options: ({ productId }) => ({
      variables: {
        id: productId,
      },
      fetchPolicy: 'network-only',
    }),
    skip: props => !props.isOpen,
  }),
)(injectIntl(VersionModal));
