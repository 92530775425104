import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';
import { HorizontalBar } from 'react-chartjs-2';

import Loading from 'components/Loading';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import apiKeyMessages from './messages';
import hostMessages from './hostMessages';
import validityMessages from './validityMessages';
import baseStyles from '../../styles/app.scss';

class ApiKeyStatistic extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };
  render() {
    const { statisticsQuery, match } = this.props;

    let allTimeStatisticsPerType = [];

    if (statisticsQuery.loading) {
      return <Loading />;
    }

    statisticsQuery.apiKeyStatisticsForApiKey.forEach(statistic => {
      // all time
      let exists = false;
      allTimeStatisticsPerType.forEach(allTimeStat => {
        if (allTimeStat.endpoint === statistic.endpoint) {
          // eslint-disable-next-line no-param-reassign
          allTimeStat.count += statistic.count;
          exists = true;
        }
      });

      if (!exists) {
        allTimeStatisticsPerType.push({ ...statistic });
      }
    });

    // Prepare data for all time statistics
    allTimeStatisticsPerType = allTimeStatisticsPerType.sort(
      (a, b) => b.count - a.count,
    );
    const labels = [];
    const dataSet = [];
    allTimeStatisticsPerType.forEach(c => {
      labels.push(`${c.endpoint}`);
      dataSet.push(c.count);
    });

    const horizontalBarAllTime = {
      labels,
      datasets: [
        {
          label: '',
          backgroundColor: 'rgba(0,220,178,0.2)',
          borderColor: 'rgba(91,189,170,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(91,189,170,0.4)',
          hoverBorderColor: 'rgba(91,189,170,1)',
          data: dataSet,
        },
      ],
    };

    return (
      <div>
        <h3>Statistics</h3>
        <h4>All Time Api Requests</h4>
        <Row>
          <Col md={10} xs={12}>
            <div>
              <HorizontalBar
                data={horizontalBarAllTime}
                options={{ legend: false, maintainAspectRatio: false }}
                height={allTimeStatisticsPerType.length * 30}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const apiKeyStatisticsQuery = gql`
  query statisticsQuery($apiKeyId: ID!) {
    apiKeyStatisticsForApiKey(apiKeyId: $apiKeyId) {
      id
      apiKeyId
      serverApiKeyId
      endpoint
      date
      count
    }
  }
`;

export default compose(
  graphql(apiKeyStatisticsQuery, {
    name: 'statisticsQuery',
    options: ({ match }) => ({
      variables: {
        apiKeyId: match.params.id,
      },
    }),
  }),
)(AuthenticationWrapper(injectIntl(withStyles(baseStyles)(ApiKeyStatistic))));
