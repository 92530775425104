exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body{background-color:#2e4b5f}._21L8U{margin:auto;margin-top:150px;padding:0 45px;overflow-x:hidden;height:100%;max-width:480px}@media(max-width:1200px){._21L8U{margin-top:50px}}@media(max-width:480px){._21L8U{padding:0 15px}}._21L8U .k5dgl{width:calc(100% - 30px);margin-top:100px;margin-left:15px}@media(max-height:666px){._21L8U .k5dgl{margin-top:20px}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"loginContainer": "_21L8U",
	"fishInWave": "k5dgl"
};