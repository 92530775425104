import React from 'react';
import PropTypes from 'prop-types';

import RouteBundler from 'components/RouteBundler';
import AuthorizationWrapper from 'components/Auth/AuthorizationWrapper';
import roles from 'core/roles';

import AnalyticsDashboard from './AnalyticsDashboard';

class Analytics extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      routes: [],
    };
  }

  componentDidMount() {
    // Note (andreas.roschal): workaround to avoid server-side rendering of AnalyticsDashboard component
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      routes: [{ path: '', component: AnalyticsDashboard, exact: true }],
    });
  }

  render() {
    return (
      <RouteBundler
        prefix={`${this.props.match.path}/`}
        routes={this.state.routes}
        me={this.props.me}
      />
    );
  }
}

// eslint-disable-next-line prettier/prettier
export default AuthorizationWrapper([roles.VIEW_DASHBOARD_STATISTICS])(Analytics);
