import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CrudView from 'components/Crud/CrudView';
import customerMessages from './messages';
import billingDataMessages from './billingDataMessages';
import baseStyles from '../../styles/app.scss';

class CustomerView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };
  render() {
    const modelQueryAttributes = [
      'id',
      'name',
      'email',
      {
        billingData: [
          'id',
          'contactName',
          'street',
          'number',
          'zip',
          'city',
          'country',
          'vatNumber',
          'vat',
          'paymentType',
        ],
        parentColumn: 'customerId',
      },
    ];

    const viewAttributes = {
      name: { type: 'text', required: true },
      email: { type: 'text', required: true },
      billingData: {
        type: 'model',
        viewAttributes: {
          contactName: { type: 'text', required: false },
          street: { type: 'text', required: true },
          number: { type: 'text', required: true },
          zip: { type: 'text', required: true },
          city: { type: 'text', required: true },
          country: { type: 'text', required: true },
          vatNumber: { type: 'text', required: false },
          vat: { type: 'text', required: false },
          paymentType: {
            type: 'enum',
            required: false,
            options: ['Invoice', 'CreditCard'],
          },
        },
      },
    };
    const messages = {
      messageList: customerMessages,
      billingData: { messageList: billingDataMessages },
    };
    return (
      <CrudView
        headerName="Customer"
        modelName="customer"
        modelQueryAttributes={modelQueryAttributes}
        messages={messages}
        viewAttributes={viewAttributes}
        match={this.props.match}
      />
    );
  }
}

export default withStyles(baseStyles)(CustomerView);
