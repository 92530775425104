import { defineMessages } from 'react-intl';

const messages = defineMessages({
  id: {
    id: 'billingData.id',
    defaultMessage: 'Id',
    description: 'Label for id in billingData',
  },
  contactName: {
    id: 'billingData.contactName',
    defaultMessage: 'Contact Name',
    description: 'Label for contact name in billingData',
  },
  street: {
    id: 'billingData.street',
    defaultMessage: 'Street',
    description: 'Label for street in billingData',
  },
  number: {
    id: 'billingData.number',
    defaultMessage: 'Number',
    description: 'Label for number in billingData',
  },
  zip: {
    id: 'billingData.zip',
    defaultMessage: 'Zip',
    description: 'Label for zip in billingData',
  },
  city: {
    id: 'billingData.city',
    defaultMessage: 'City',
    description: 'Label for city in billingData',
  },
  country: {
    id: 'billingData.country',
    defaultMessage: 'Country',
    description: 'Label for country in billingData',
  },
  vatNumber: {
    id: 'billingData.vatNumber',
    defaultMessage: 'Vat Number',
    description: 'Label for vatNumber in billingData',
  },
  vat: {
    id: 'billingData.vat',
    defaultMessage: 'Vat',
    description: 'Label for vat in billingData',
  },
  paymentType: {
    id: 'billingData.paymentType',
    defaultMessage: 'Payment Type',
    description: 'Label for paymentType in billingData',
  },
  invoice: {
    id: 'billingData.invoice',
    defaultMessage: 'Invoice',
    description: 'Label for invoice in billingData',
  },
  creditCard: {
    id: 'billingData.creditCard',
    defaultMessage: 'Credit Card',
    description: 'Label for creditCard in billingData',
  },
});

export default messages;
