import { defineMessages } from 'react-intl';

const messages = defineMessages({
  id: {
    id: 'apiKey.id',
    defaultMessage: 'Id',
    description: 'Label for id in apiKey',
  },
  secet: {
    id: 'apiKey.secret',
    defaultMessage: 'Secret',
    description: 'Label for secret in apiKey',
  },
  name: {
    id: 'apiKey.name',
    defaultMessage: 'Name',
    description: 'Label for name in apiKey',
  },
  description: {
    id: 'apiKey.description',
    defaultMessage: 'Description',
    description: 'Label for description in apiKey',
  },
  customer: {
    id: 'apiKey.customer',
    defaultMessage: 'Customer',
    description: 'Label for customer in apiKey',
  },
  enabled: {
    id: 'apiKey.enabled',
    defaultMessage: 'Enabled',
    description: 'Label for enabled in apiKey',
  },
  secret: {
    id: 'apiKey.secret',
    defaultMessage: 'Secret',
    description: 'Label for secret in apiKey',
  },
  validationInterval: {
    id: 'apiKey.validationInterval',
    defaultMessage: 'Validation Interval',
    description: 'Label for validationInterval in apiKey',
  },
  autoRenew: {
    id: 'apiKey.autoRenew',
    defaultMessage: 'Auto Renew',
    description: 'Label for autoRenew in apiKey',
  },
  lastSuccessfulValidation: {
    id: 'apiKey.lastSuccessfulValidation',
    defaultMessage: 'Last Successful Validation',
    description: 'Label for lastSuccessfulValidation in apiKey',
  },
  license: {
    id: 'apiKey.license',
    defaultMessage: 'License',
    description: 'Label for license select in apiKey',
  },
  billingData: {
    id: 'apiKey.billingData',
    defaultMessage: 'Billing Data',
    description: 'Label for billing data select in apiKey',
  },
  ativeAndFutureValidities: {
    id: 'apiKey.ativeAndFutureValidities',
    defaultMessage: 'Active and future Validities',
    description: 'Label for ativeAndFutureValidities in apiKey table',
  },
});

export default messages;
