import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Login.scss';
import messages from '../messages';
import LoginForm from './LoginForm';
import { withLogin, meQuery } from '../withLogin';
import TopNavigationLogin from '../../AppLayout/TopNavigationLogin';
import roleNames from '../../../core/roles';

class Login extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };

    this.submitLoginForm = this.submitLoginForm.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  setErrors(errors) {
    this.setState({
      errors: errors.map(err => err.message),
    });
  }

  async redirect() {
    const queryResult = await this.context.client.query({
      query: meQuery,
      fetchPolicy: 'network-only',
    });
    if (queryResult && queryResult.data && queryResult.data.me) {
      const roles = queryResult.data.me.roles.map(ele => ele.name);
      if (
        roles.includes(roleNames.VIEW_DASHBOARD_STATISTICS) &&
        roles.length === 1
      ) {
        this.props.history.push({ pathname: '/statistics/' });
      } else {
        this.props.history.push({ pathname: '/app/' });
      }
    }
  }

  async submitLoginForm({ username, password, rememberMe }) {
    const { login, intl } = this.props;
    const result = await login(username, password, rememberMe);

    if (!result || !result.data.login.success) {
      try {
        this.setErrors([
          {
            message: intl.formatMessage(
              messages[result.data.login.errorMessageId],
            ),
          },
        ]);
      } catch (e) {
        this.setErrors([
          { message: intl.formatMessage(messages.invalidCredentials) },
        ]);
      }
    } else {
      this.redirect();
    }
  }

  render() {
    return (
      <div className={s.loginContainer}>
        <TopNavigationLogin />
        <LoginForm onSubmit={this.submitLoginForm} errors={this.state.errors} />
        <img className={s.fishInWave} src="/fishInWave.svg" alt="" />
      </div>
    );
  }
}

export default withRouter(withLogin(injectIntl(withStyles(s)(Login))));
