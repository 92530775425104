/* eslint-disable react/prop-types, max-len, jsx-a11y/label-has-for */

import React from 'react';
import { change } from 'redux-form';
import Textarea from 'react-textarea-autosize';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
// import FaTrash from 'react-icons/lib/fa/trash';

import formMessages from './messages';
import Spinner from '../Spinner';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import SelectAlt, { CustomSingleValue } from './Select';

// workaround for https://www.chromestatus.com/features/5093566007214080
const onTouchStart = event => {
  event.target.focus();
};

const renderInput = ({
  input,
  label,
  type,
  meta: { asyncValidating, touched, error, warning },
  id,
  placeholder,
  disabled,
  autoFocus,
}) => (
  <div className="form-group">
    <Spinner show={asyncValidating} />
    {label && <label htmlFor={id}>{label}</label>}
    <input
      {...input}
      id={id}
      type={type}
      className="form-control"
      placeholder={placeholder}
      onTouchStart={onTouchStart}
      disabled={disabled}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      // ref={el => autoFocus && el && el.focus()}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderFileInput = ({
  input,
  label,
  meta: { asyncValidating, touched, error, warning },
  id,
  onFileChange,
}) => (
  <div className="form-group">
    <Spinner show={asyncValidating} />
    {label && (
      <label
        htmlFor={id}
        className="btn btn-default btn-block btn-file btn-file"
      >
        {label}
      </label>
    )}
    <input
      id={id}
      type="file"
      className="form-control"
      style={label ? { display: 'none' } : {}}
      onTouchStart={onTouchStart}
      onChange={onFileChange}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderCheckbox = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  id,
  style,
  disabled,
}) => (
  <div className="checkbox" style={style}>
    <label htmlFor={id}>
      <input {...input} id={id} type={type} disabled={disabled} />
      <span>{label}</span>
    </label>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderRadio = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  id,
}) => (
  <div className="radio">
    <label htmlFor={id}>
      <input {...input} id={id} type={type} />
      {label}
    </label>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderSelect = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  children,
  disabled,
  noDefaultLabel,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <select {...input} id={id} className="form-control" disabled={disabled}>
      {noDefaultLabel && (
        <option hidden disabled value="">
          {noDefaultLabel}
        </option>
      )}
      {children}
    </select>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderSelectAlt = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  options,
  styles,
  placeholder,
  isDisabled,
  isMulti,
  isSearchable,
  noOptionsMessage,
  innerLeftPadding,
}) => (
  <div className="form-group">
    {label && (
      <label style={{ fontWeight: 'bold' }} htmlFor={id}>
        {label}
      </label>
    )}
    <SelectAlt
      className="form-control-react-select"
      input={input}
      meta={{
        touched,
        error,
        warning,
      }}
      options={options}
      styles={styles}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isSearchable={isSearchable}
      noOptionsMessage={noOptionsMessage}
      innerLeftPadding={innerLeftPadding}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const MultilineOption = props => (
  <div>
    <components.Option {...props}>
      {props.data.label.map((line, i) => {
        if (i === 0) {
          return <b>{line}</b>;
        }
        return <div>{line}</div>;
      })}
    </components.Option>
  </div>
);

const renderMultilineSelect = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  options,
}) => {
  const getInitialValueObject = val => options.find(o => o.value === `${val}`);
  let initialValue = null;
  if (typeof input.value !== 'object') {
    initialValue = getInitialValueObject(input.value);
  }
  return (
    <div className="form-group">
      {label && <label htmlFor={id}>{label}</label>}
      <Select
        {...input}
        options={options}
        components={{ Option: MultilineOption, SingleValue: CustomSingleValue }}
        onBlur={() => input.onBlur(input.value)}
        value={initialValue || input.value}
      />
      {touched &&
        ((error && <p className="bg-danger">{error}</p>) ||
          (warning && <p className="bg-warning">{warning}</p>))}
    </div>
  );
};

const renderTextarea = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  placeholder,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <Textarea
      {...input}
      id={id}
      className="form-control"
      placeholder={placeholder}
    >
      {input.value}
    </Textarea>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

// eslint-disable-next-line no-unused-vars
const renderNumberInput = ({ input, label, meta, id }) => {
  function increaseNumber() {
    meta.dispatch(change(meta.form, input.name, Number(input.value) + 1));
  }

  function decreaseNumber() {
    meta.dispatch(change(meta.form, input.name, Number(input.value) - 1));
  }

  return (
    <div style={{ padding: '0 20px 20px 20px' }}>
      <button
        type="button"
        className="btn btn-primary"
        style={{ width: '100%' }}
        onClick={increaseNumber}
      >
        <i className="fa fa-plus" />
      </button>
      <input
        {...input}
        className="no-spinners"
        type="number"
        style={{ width: '100%', textAlign: 'center' }}
        min={0}
      />
      <button
        type="button"
        className="btn btn-primary"
        style={{ width: '100%' }}
        onClick={decreaseNumber}
      >
        <i className="fa fa-minus" />
      </button>
    </div>
  );
};

// eslint-disable-next-line react/prop-types, max-len, prettier/prettier
const renderTime = ({ input, label, meta: { touched, error, warning }, id, handleUpdate, manualUpdate, initialValue }) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <div className="input-group">
      <TimePicker
        meta={{
          touched,
          error,
          warning,
        }}
        manualUpdate={manualUpdate}
        input={input}
        handleUpdate={handleUpdate}
        initialValue={initialValue}
      />
    </div>
  </div>
);

// eslint-disable-next-line react/prop-types, max-len, prettier/prettier
const renderDate = ({ input, label, meta: { touched, error, warning }, id, placeholder, disabled }) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <div className="input-group">
      <DatePicker
        input={input}
        meta={{
          touched,
          error,
          warning,
        }}
        className="form-control"
        placeholder={placeholder}
        disabledKeyboardNavigation
        disabled={disabled}
      />
    </div>
  </div>
);

/* eslint-disable react/no-array-index-key */
const renderGenericFieldArray = ({
  fields,
  meta: { error },
  name,
  renderField,
  withAdd = true,
  // withRemove = true,
  pushValue,
  singleRows,
  customProps,
  title,
  renderSubArray = false,
}) => {
  const classNames = ['field-array'];
  const removeButtonClassNames = ['btn', 'btn-danger'];
  if (singleRows) {
    classNames.push('field-array--single-rows');
    removeButtonClassNames.push('btn--no-border');
    removeButtonClassNames.push('btn--no-hover');
    removeButtonClassNames.push('remove-button');
  }
  return (
    <div>
      {renderSubArray ? <h2>{title}</h2> : <h4>{title}</h4>}
      <ul className={classNames.join(' ')}>
        {fields.map((field, index) => (
          <li key={`${name}__${index}`} className="clearfix">
            {renderField(customProps, fields, index)}
            {/* withRemove && (
            <button
              type="button"
              className={removeButtonClassNames.join(' ')}
              onClick={() => {
                fields.remove(index);
                if (handleDelete) {
                  handleDelete();
                }
              }}
            >
              <FaTrash />
              {!singleRows && <FormattedMessage {...formMessages.remove} />}
            </button>
            ) */}
          </li>
        ))}
        {error && <li className="error">{error}</li>}
        {withAdd && (
          <li>
            <hr />
            <button
              type="button"
              className="btn btn-success"
              onClick={() => fields.push(pushValue)}
            >
              <i className="fa fa-plus fa--prepended" />
              <FormattedMessage {...formMessages.add} />
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};
/* eslint-enable react/no-array-index-key */

export default {
  renderInput,
  renderFileInput,
  renderCheckbox,
  renderRadio,
  renderSelect,
  renderSelectAlt,
  renderMultilineSelect,
  renderTextarea,
  renderNumberInput,
  renderTime,
  renderDate,
  renderGenericFieldArray,
};
