import { defineMessages } from 'react-intl';

const messages = defineMessages({
  name: {
    id: 'host.name',
    defaultMessage: 'Name',
    description: 'Label for name in host',
  },
  description: {
    id: 'host.description',
    defaultMessage: 'Description',
    description: 'Label for description in host',
  },
  enabled: {
    id: 'host.enabled',
    defaultMessage: 'Enabled',
    description: 'Label for enabled in host',
  },
});

export default messages;
