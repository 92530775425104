import { defineMessages } from 'react-intl';

const messages = defineMessages({
  startDate: {
    id: 'renew.startDate',
    defaultMessage: 'Start Date',
    description: 'Label for startDate in renew',
  },
  license: {
    id: 'renew.license',
    defaultMessage: 'License',
    description: 'Label for License in renew',
  },
  required: {
    id: 'renew.required',
    defaultMessage: 'Required',
    description: 'Label for required in renew',
  },
  submit: {
    id: 'renew.submit',
    defaultMessage: 'Submit',
    description: 'Label for submit in renew',
  },
});

export default messages;
