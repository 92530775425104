import { defineMessages } from 'react-intl';

const messages = defineMessages({
  name: {
    id: 'product.name',
    defaultMessage: 'Name',
    description: 'Label for name in product',
  },
  key: {
    id: 'product.key',
    defaultMessage: 'Key',
    description: 'Label for key in product',
  },
  shortDescription: {
    id: 'product.shortDescription',
    defaultMessage: 'Short Description',
    description: 'Label for shortDescription in product',
  },
  description: {
    id: 'product.description',
    defaultMessage: 'Description',
    description: 'Label for description in product',
  },
  productVersionLifespan: {
    id: 'product.productVersionLifespan',
    defaultMessage: 'Version lifespan',
    description: 'Label for productVersionLifespan in product',
  },
  isOnPremises: {
    id: 'product.isOnPremises',
    defaultMessage: 'On premises',
    description: 'Label for isOnPremises in product',
  },
});

export default messages;
