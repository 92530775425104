/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import render from 'components/ReduxForm/renderField';
import validations from 'components/ReduxForm/validations';
import formMessages from 'components/ReduxForm/messages';

import messages from './messages';

const validate = values => {
  const errors = {};
  if (!validations.required(values.startDate)) {
    errors.startDate = <FormattedMessage {...formMessages.required} />;
  }
  if (!validations.required(values.license)) {
    errors.license = <FormattedMessage {...formMessages.required} />;
  }
  return errors;
};

class RenewForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    licenses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <Field
            id="startDate"
            name="startDate"
            label={<FormattedMessage {...messages.startDate} />}
            component={render.renderDate}
          />
          <Field
            id="license"
            name="license"
            type="string"
            label={<FormattedMessage {...messages.license} />}
            component={render.renderMultilineSelect}
            options={this.props.licenses.map(license => ({
              value: license.id,
              label: [license.name, `description: ${license.description}`, `enabled: ${license.enabled}`, `renewable: ${license.renewable}`],
              controlLabel: <span style={{ color: 'black' }}><b> {`${license.name}`} | description:</b> {`${license.description}`}, <b>enabled:</b> {`${license.enabled}`}, <b>renewable:</b> {`${license.renewable}`}</span>
            }))}
          />
          <button
            type="submit"
            className="btn btn-primary"
            disabled={this.props.submitting}
          >
            <FormattedMessage {...formMessages.submit} />
          </button>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'autorenewForm',
  validate,
})(injectIntl(RenewForm));
