import { defineMessages } from 'react-intl';

const messages = defineMessages({
  name: {
    id: 'role.name',
    defaultMessage: 'Name',
    description: 'Label for name in role',
  },
  role: {
    id: 'role.role',
    defaultMessage: 'Role',
    description: 'Label for role in userRole',
  },
});

export default messages;
