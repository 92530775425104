import Login from './login';
import App from './app';
import StatisticsPage from './statistics';

const routes = [
  {
    path: '/',
    component: Login,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/app',
    component: App,
  },
  {
    path: '/statistics',
    component: StatisticsPage,
  },
];

export default routes;
