import { defineMessages } from 'react-intl';

const messages = defineMessages({
  id: {
    id: 'customer.id',
    defaultMessage: 'Id',
    description: 'Label for id in customer',
  },
  name: {
    id: 'customer.name',
    defaultMessage: 'Name',
    description: 'Label for name in customer',
  },
  email: {
    id: 'customer.email',
    defaultMessage: 'Email',
    description: 'Label for email in customer',
  },
});

export default messages;
