import gql from 'graphql-tag';

const meQuery = gql`
  query data {
    me {
      id
      username
      roles {
        id
        name
      }
    }
  }
`;

export default meQuery;
