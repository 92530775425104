import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CrudTable from 'components/Crud/CrudTable';
import messages from './messages';
import baseStyles from '../../styles/app.scss';

class CustomerTable extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  };
  render() {
    const modelQueryAttributes = [
      'id',
      'name',
      { billingData: ['id', 'contactName'] },
    ];
    const modelQueryArguments = {
      attributes: modelQueryAttributes,
    };
    const tableAttributes = {
      name: { width: 200 },
    };
    return (
      <CrudTable
        headerName="Customers"
        modelName="customers"
        modelQueryArguments={modelQueryArguments}
        messages={messages}
        tableAttributes={tableAttributes}
        match={this.props.match}
      />
    );
  }
}

export default withStyles(baseStyles)(CustomerTable);
