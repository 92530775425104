import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CrudView from 'components/Crud/CrudView';
import userMessages from './messages';
import roleMessages from './roleMessages';
import baseStyles from '../../styles/app.scss';

class UserView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };
  render() {
    const modelQueryAttributes = [
      'id',
      'username',
      {
        roles: ['id'],
        parentColumn: 'userId',
        joinTable: 'userRole',
        joinAttributes: ['id'],
        isSelect: true,
      },
    ];

    const viewAttributes = {
      username: { type: 'text', required: true },
      userRole: {
        type: 'model',
        viewAttributes: {
          role: {
            type: 'selectModel',
            query: 'roles',
            columnName: 'roleId',
            viewAttribute: 'name',
            required: true,
          },
        },
      },
    };

    const messages = {
      messageList: userMessages,
      userRole: { messageList: roleMessages },
    };
    return (
      <div>
        <CrudView
          headerName="User"
          modelName="user"
          modelQueryAttributes={modelQueryAttributes}
          messages={messages}
          viewAttributes={viewAttributes}
          match={this.props.match}
        />
      </div>
    );
  }
}

export default withStyles(baseStyles)(UserView);
