import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';

import ApiKeyStatisticsLineChart from './index';

const messages = defineMessages({
  close: {
    id: 'apiKeyStatisticsModal.close',
    defaultMessage: 'Close',
    description: 'Close Button label in Api Key Statistics Modal',
  },
  modalHeader: {
    id: 'apiKeyStatisticsModal.modalHeader',
    defaultMessage: 'Statistics for ApiKey:',
    description: 'Header label for Api Key Statistics Modal',
  },
});

class ApiKeyStatisticsModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    statistics: PropTypes.arrayOf(PropTypes.object).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    apiKey: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { apiKey } = this.props;
    if (!apiKey) {
      return <d />;
    }

    const descriptionText = apiKey.description ? `(${apiKey.description})` : '';
    return (
      <Modal show={this.props.show} backdrop>
        <Modal.Body>
          <div>
            <div>
              <div>
                <h3>
                  <FormattedMessage {...messages.modalHeader} />
                  <br />
                  {apiKey && `${apiKey.name} ${descriptionText}`}
                </h3>
              </div>
            </div>
            <div style={{ marginBottom: '5px' }}>
              <ApiKeyStatisticsLineChart
                statistics={this.props.statistics}
                apiKey={this.props.apiKey}
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  this.props.onClose();
                }}
              >
                <FormattedMessage {...messages.close} />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ApiKeyStatisticsModal;
