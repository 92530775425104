import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import tabsStyle from 'react-tabs/style/react-tabs.scss';
import Cookies from 'universal-cookie';

import s from './Tabs.scss';
import Loading from '../Loading';

const cookies = new Cookies();
const COOKIE_NAME = 'danubeLicense-currentTab';

export const defaultTabTitles = ['On-Premise', 'Cloud'];

class Tabs_ extends React.Component {
  static propTypes = {
    tabTitles: PropTypes.arrayOf(PropTypes.string),
    active: PropTypes.arrayOf(PropTypes.integer),
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    tabTitles: defaultTabTitles,
    active: 1,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentTab: null,
    };
  }

  async componentDidMount() {
    let currentTab = await cookies.get(COOKIE_NAME);
    currentTab = parseInt(currentTab, 10);

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      currentTab,
    });
  }

  static getTab(tabTitle, active) {
    if (active) {
      return (
        <Tab>
          <h3 className={s.tabTitle}>{tabTitle}</h3>
        </Tab>
      );
    }
    return (
      <Tab>
        <h3 className={s.tabTitleGrey}>{tabTitle}</h3>
      </Tab>
    );
  }

  render() {
    const { tabTitles, active, children } = this.props;
    const { currentTab } = this.state;

    if (currentTab == null) return <Loading />;

    return (
      <Tabs
        selectedIndex={currentTab}
        onSelect={index => {
          if (active[index]) {
            cookies.set(COOKIE_NAME, index, { path: '/' });
            this.setState({ currentTab: index });
            return true;
          }
          return false;
        }}
      >
        <TabList>
          {tabTitles.map((tabTitle, index) =>
            Tabs_.getTab(tabTitle, active[index]),
          )}
        </TabList>

        {React.Children.map(children, child => (
          <TabPanel>{child}</TabPanel>
        ))}
      </Tabs>
    );
  }
}

export default withStyles(tabsStyle, s)(Tabs_);
