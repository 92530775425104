import { defineMessages } from 'react-intl';

const messages = defineMessages({
  required: {
    id: 'validation.required',
    defaultMessage: 'This field is required',
    description: 'Validation error message for required fields',
  },
  invalidJson: {
    id: 'validation.invalidJson',
    defaultMessage: 'Invalid JSON format',
    description: 'Validation error message for json fields',
  },
  numberTooLarge: {
    id: 'validation.numberTooLarge',
    defaultMessage: 'Number too large',
    description:
      'Validation error message for number fields if too large for db',
  },
  negativeNumber: {
    id: 'validation.negativeNumber',
    defaultMessage: 'Number must be positive',
    description: 'Validation error message for non negative number fields',
  },
  notInteger: {
    id: 'validation.notInteger',
    defaultMessage: 'Number must be integer',
    description: 'Validation error message for integer fields',
  },
  yes: {
    id: 'form.yes',
    defaultMessage: 'Yes',
    description: 'Boolean option label for true',
  },
  no: {
    id: 'form.no',
    defaultMessage: 'No',
    description: 'Boolean option label for false',
  },
  submit: {
    id: 'form.submit',
    defaultMessage: 'Save',
    description: 'Default submit button label',
  },
  cancel: {
    id: 'form.cancel',
    defaultMessage: 'Cancel',
    description: 'Default cancel button label',
  },
  unsavedChanges: {
    id: 'form.unsavedChanges',
    defaultMessage: 'This form contains unsaved changes',
    description: 'Info message if form contains unsaved changes',
  },
  add: {
    id: 'form.add',
    defaultMessage: 'Add',
    description: 'Default value for add (entry) buttons',
  },
  remove: {
    id: 'form.remove',
    defaultMessage: 'Delete',
    description: 'Default value for remove (entry) buttons',
  },
  pleaseChoose: {
    id: 'form.pleaseChoose',
    defaultMessage: 'Please choose ...',
    description: 'Default value for empty option for select fields',
  },
});

export default messages;
