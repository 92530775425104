import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from 'rc-time-picker/assets/index.css';

import { getTimeSlots, calculateInitialTimeValue } from '../../util';
import s from './TimePicker.scss';

const interval = 15;

const options = getTimeSlots({ interval }).map(timeSlot => ({
  value: timeSlot,
  label: timeSlot,
}));

const initialTime = calculateInitialTimeValue({ interval }).format('HH:mm');
const initialTimeValue = {
  value: initialTime,
  label: initialTime,
};

class TimePicker extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape({
        id: PropTypes.string,
      }),
      warning: PropTypes.bool,
    }).isRequired,
    handleUpdate: PropTypes.func.isRequired,
    manualUpdate: PropTypes.bool.isRequired,
    initialValue: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let preset = null;
    const { value } = this.props.initialValue;
    if (value) {
      preset = { value, label: value };
    }
    this.handleChange(preset || initialTimeValue);
    this.props.handleUpdate();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.manualUpdate &&
      !this.props.meta.touched &&
      prevProps.input.value.value !== this.props.input.value.value
    ) {
      this.handleChange(this.props.input.value);
      this.props.handleUpdate();
    }
  }
  handleChange(value) {
    this.props.input.onChange(value);
  }

  render() {
    const {
      input,
      initialValue,
      meta: { touched, error, warning },
    } = this.props;

    let preset = null;
    const { value: timeValue } = initialValue;
    if (timeValue) {
      preset = { value: timeValue, label: timeValue };
    }
    return (
      <div className={s.timeSelector}>
        <Select
          defaultValue={preset || initialTimeValue}
          options={options}
          onChange={value => {
            input.onChange(value);
            this.props.handleUpdate();
          }}
          onBlur={() => input.onBlur(input.value)}
          value={input.value}
        />
        {touched &&
          ((error && <p className="bg-danger">{error}</p>) ||
            (warning && <p className="bg-warning">{warning}</p>))}
      </div>
    );
  }
}

export default withStyles(s, baseStyles)(TimePicker);
