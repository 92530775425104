/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import Loading from 'components/Loading';
import Alert, { alertOpts } from 'components/Alert';

import QueryGenerator from './QueryGenerator';
import CrudViewForm from './CrudViewForm';
import { DB_DATE_FORMAT } from '../../util';

class CrudView extends React.Component {
  static propTypes = {
    headerName: PropTypes.string.isRequired,
    modelName: PropTypes.string.isRequired,
    modelQueryAttributes: PropTypes.array.isRequired,
    messages: PropTypes.object.isRequired,
    viewAttributes: PropTypes.object.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    isSubForm: PropTypes.bool,
    parentEntryId: PropTypes.string,
    parentColumn: PropTypes.string,
    subFormData: PropTypes.object,
    modelId: PropTypes.string,
    joinedTable: PropTypes.bool,
    // redux form attributes to remove correct form in recursion
    fields: PropTypes.object,
    forms: PropTypes.object.isRequired,
    fieldIndex: PropTypes.number,
  };

  static contextTypes = {
    client: PropTypes.object,
  };

  static defaultProps = {
    joinedTable: null,
    isSubForm: false,
    match: null,
    parentEntryId: null,
    parentColumn: '',
    subFormData: null,
    modelId: null,
    fields: null,
    fieldIndex: 0,
  };

  static reduceSelectObjectsToId({ attributes, modelQueryAttributes }) {
    if (!attributes) return null;

    const updatedAttributes = attributes;
    Object.keys(attributes).forEach(key => {
      const attribute = modelQueryAttributes.find(
        val =>
          typeof val === 'object' && val[key] && val.isSelect && !val.joinTable,
      );
      if (attribute) {
        updatedAttributes[attribute.parentColumn] = attributes[key].id;
        delete updatedAttributes[key];
      }
    });
    return updatedAttributes;
  }

  constructor(props) {
    super(props);
    this.getModelVariablesFromValues = this.getModelVariablesFromValues.bind(
      this,
    );
    this.parseViewAttributes = this.parseViewAttributes.bind(this);
    this.queryModel = this.queryModel.bind(this);
    this.mutateModel = this.mutateModel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.injectDefaultValues = this.injectDefaultValues.bind(this);

    this.state = {
      initialized: false,
      alert: {
        ...alertOpts.success,
      },
    };
  }

  componentWillMount() {
    this.queryModel();
  }

  getModelVariablesFromValues(formValues) {
    const { modelQueryAttributes, modelName, viewAttributes } = this.props;
    const nestedObjectList = [];
    modelQueryAttributes.forEach(val => {
      if (typeof val === 'object') {
        nestedObjectList.push(Object.keys(val)[0]);
      }
    });

    const newValues = {};
    Object.keys(formValues).forEach(attributeName => {
      let newVal = formValues[attributeName];
      const viewAttributeName = attributeName.endsWith('Id')
        ? attributeName.replace('Id', '')
        : attributeName;
      let removeValue = false;
      if (viewAttributeName && viewAttributes[viewAttributeName]) {
        const { type, readOnly } = viewAttributes[viewAttributeName];
        if (type === 'int') {
          newVal = parseInt(newVal, 10);
        } else if (type === 'float') {
          newVal = parseFloat(newVal, 10);
        } else if (type === 'date') {
          newVal = moment(newVal, 'DD.MM.YYYY').format(DB_DATE_FORMAT);
        } else if (type === 'boolean') {
          newVal = newVal === 'true' || newVal === true ? 1 : 0;
        } else if (type === 'selectModel') {
          // get value field from react-select object
          if (typeof newVal === 'object') {
            newVal = newVal.value;
          }
        }
        if (readOnly) removeValue = true;
      }
      if (!removeValue) newValues[attributeName] = newVal;
    });

    if (this.props.joinedTable) {
      newValues[`${this.props.joinedTable}Id`] = formValues.id;
      delete newValues.id;
    }
    const modelVariables = {
      ..._.omit(newValues, ['__typename', ...nestedObjectList]),
    };
    const variables = {};
    variables[modelName] = modelVariables;
    return variables;
  }

  closeAlert() {
    this.setState({ alert: { show: false } });
  }

  async mutateModel({ operation, variables }) {
    const { modelName, modelQueryAttributes } = this.props;
    let { curModelId } = this.state;
    let modelMutationGraphql;
    if (operation === 'update') {
      modelMutationGraphql = QueryGenerator.getModelMutation({
        modelName,
        attributes: modelQueryAttributes,
        operation,
      });
    } else {
      modelMutationGraphql = QueryGenerator.getModelMutation({
        modelName,
        attributes: modelQueryAttributes,
        operation,
      });
    }
    // execute mutation
    try {
      const mutationResult = await this.context.client.mutate({
        mutation: modelMutationGraphql,
        variables,
      });
      if (operation === 'create') {
        const queryModelName =
          modelName.charAt(0).toUpperCase() + modelName.slice(1);
        curModelId = mutationResult.data[`create${queryModelName}`].id;
        this.setState({ alert: { show: true }, curModelId });
        await this.queryModel();
      } else {
        this.setState({ alert: { show: true } });
      }
      if (!mutationResult.errors) return true;
    } catch (e) {
      this.setState({
        alert: {
          ...alertOpts.error,
          show: true,
          msg: e.message,
        },
      });
    }
    return false;
  }

  // eslint-disable-next-line
  async handleSubmit(formValues) {
    const {
      modelName,
      modelQueryAttributes,
      match,
      isSubForm,
      parentEntryId,
      parentColumn,
    } = this.props;
    const { curModelId } = this.state;
    // filter nested objects
    const nestedObjectList = [];
    modelQueryAttributes.forEach(val => {
      if (typeof val === 'object' && val.parentColumn) {
        nestedObjectList.push(Object.keys(val)[0]);
      }
    });

    const variables = this.getModelVariablesFromValues(formValues);

    let operation = 'update';
    if (isSubForm) {
      if (parentEntryId) {
        variables[modelName][parentColumn] = parentEntryId;
        if (curModelId) {
          // parent model and sub model already exist
          operation = 'update';
        } else {
          // parent model exists, but submodel is new
          operation = 'create';
        }
      }
      // top level model
    } else if (
      curModelId ||
      (match &&
        match.params &&
        match.params.id &&
        _.isNumber(parseInt(match.params.id, 10)))
    ) {
      // edit entry
      operation = 'update';
    } else {
      // new entry
      operation = 'create';
    }
    const success = await this.mutateModel({ operation, variables });
    if (operation === 'create' && !isSubForm) {
      // cannot use history.push() as reactRouter will not work correctly with redux forms
      if (this.modelQueryResult && this.modelQueryResult.id) {
        window.location = this.modelQueryResult.id;
      } else if (success) {
        this.setState({ alert: { show: true } });
      }
    }
  }

  async handleDelete() {
    const { curModelId } = this.state;
    const { joinedTable, parentColumn, parentEntryId } = this.props;
    if (curModelId) {
      const variables = {};
      variables[this.props.modelName] = {};
      if (joinedTable) {
        variables[this.props.modelName][`${joinedTable}Id`] = curModelId;
        variables[this.props.modelName][parentColumn] = parentEntryId;
      } else {
        variables[this.props.modelName].id = curModelId;
      }
      await this.mutateModel({ operation: 'remove', variables });
    }
  }

  // returns the same view attributes, but queries those of type 'selectModel' and adds result as attribute
  async parseViewAttributes(viewAttributes) {
    return Promise.all(
      Object.keys(viewAttributes).map(async key => {
        const viewAttribute = viewAttributes[key];
        // query model for options in select and pass to CrudViewForm
        if (viewAttribute.type === 'selectModel') {
          const attributes = Array.isArray(viewAttribute.viewAttribute)
            ? ['id', ...viewAttribute.viewAttribute]
            : ['id', viewAttribute.viewAttribute];
          const modelQueryGraphql = QueryGenerator.getModelQuery({
            modelName: viewAttribute.query,
            attributes,
          });
          const queryResult = await this.context.client.query({
            query: modelQueryGraphql,
            fetchPolicy: 'network-only',
          });
          viewAttribute.queryResult =
            queryResult.data[viewAttribute.query] || [];
        }
        return viewAttribute;
      }),
    );
  }

  async queryModel() {
    const {
      modelName,
      modelQueryAttributes,
      match,
      subFormData,
      modelId,
      joinedTable,
    } = this.props;
    let curModelId = this.state.curModelId || modelId;
    // query modelId from url if not in new dialog
    if (
      !curModelId &&
      match &&
      match.path &&
      !match.path.endsWith('new') &&
      match.params
    ) {
      const { params } = this.props.match;
      // check if id is set correctly in url
      if (params && params.id && _.isNumber(parseInt(params.id, 10))) {
        curModelId = params.id;
      }
    }
    // query existing url
    if (!subFormData && curModelId) {
      // load existing model
      const argumentObject = { id: { type: 'ID!', val: curModelId } };
      const queryModelName = joinedTable || modelName;
      const modelQueryGraphql = QueryGenerator.getModelQuery({
        modelName: queryModelName,
        attributes: modelQueryAttributes,
        argumentObject,
      });
      // link variables
      const variables = {};
      Object.keys(argumentObject).forEach(key => {
        variables[key] = argumentObject[key].val;
      });
      const queryResult = await this.context.client.query({
        query: modelQueryGraphql,
        variables,
        fetchPolicy: 'network-only',
      });
      let result = queryResult.data[queryModelName];
      if (result) {
        // reduce object queried into selects to id values
        result = CrudView.reduceSelectObjectsToId({
          attributes: result,
          modelQueryAttributes,
        });
      }
      this.modelQueryResult = result || {};
    } else {
      this.modelQueryResult = CrudView.reduceSelectObjectsToId({
        attributes: subFormData,
        modelQueryAttributes,
      });
    }

    await this.parseViewAttributes(this.props.viewAttributes);
    this.setState({
      initialized: true,
      curModelId,
    });
  }

  injectDefaultValues(values) {
    const defaultValues = JSON.parse(JSON.stringify(values));
    const { viewAttributes } = this.props;
    Object.keys(viewAttributes).forEach(key => {
      const { type } = viewAttributes[key];
      // set boolean values to false if not set (to register redux values)
      if ('default' in viewAttributes[key]) {
        defaultValues[key] = viewAttributes[key].default;
      } else if ('defaultSelector' in viewAttributes[key]) {
        const ats = viewAttributes[key].defaultSelector;
        if (ats.formPrefix && ats.field) {
          const form = this.props.forms[
            `${ats.formPrefix}-${this.props.parentEntryId}`
          ];
          defaultValues[ats.target] = form.values[ats.field];
        }
      } else if (type === 'boolean' && values[key] !== true) {
        defaultValues[key] = false;
      } else if (type === 'date') {
        if (defaultValues[key]) {
          defaultValues[key] = moment(values[key], DB_DATE_FORMAT).format(
            'DD.MM.YYYY',
          );
        } else {
          defaultValues[key] = moment();
        }
      }
    });
    return defaultValues;
  }

  render() {
    const { modelId, fields, fieldIndex, isSubForm, joinedTable } = this.props;
    const { initialized, alert, curModelId } = this.state;
    if (!initialized) return <Loading />;

    const formName = `${this.props.headerName}-${modelId || curModelId}`;
    const initialValues = this.injectDefaultValues(this.modelQueryResult || {});
    return (
      <div>
        <CrudViewForm
          initialValues={initialValues}
          form={formName}
          headerName={this.props.headerName}
          modelName={this.props.modelName}
          modelQueryAttributes={this.props.modelQueryAttributes}
          modelQueryResult={this.modelQueryResult}
          messages={this.props.messages}
          viewAttributes={this.props.viewAttributes}
          match={this.props.match}
          onSubmit={this.handleSubmit}
          handleDelete={this.handleDelete}
          modelId={curModelId}
          parentEntryId={curModelId}
          isSubForm={isSubForm}
          fields={fields}
          fieldIndex={fieldIndex}
          joinedTable={joinedTable}
        />
        <Alert {...alert} onConfirm={this.closeAlert} />
      </div>
    );
  }
}

const mapState = state => ({
  forms: state.form,
});

export default connect(mapState)(CrudView);
