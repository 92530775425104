import React from 'react';

import Statistics from '../../components/Statistics';

class StatisticsPage extends React.Component {
  render() {
    return (
      <div>
        <Statistics />
      </div>
    );
  }
}

export default StatisticsPage;
