exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1cFKi,._1cFKi h1{color:#ccf8ef}._1cFKi h1{font-size:32px;font-size:2rem;text-transform:uppercase}@media(max-width:768px){._1cFKi h1{margin-top:30px}}@media(max-height:480px){._1cFKi h1{margin-top:30px}}._1cFKi fieldset{margin-top:-10px;color:#ccf8ef}._1cFKi fieldset label{color:#ccf8ef}._1cFKi fieldset input{background-color:#2e4b5f;border:2px solid #00dcb2;border-radius:3px}._1cFKi fieldset input:active,._1cFKi fieldset input:focus,._1cFKi fieldset input:hover{background-color:#2e4b5f;border:2px solid #00dcb2}._1cFKi fieldset input:-webkit-autofill,._1cFKi fieldset input:-webkit-autofill:focus,._1cFKi fieldset input:-webkit-autofill:hover{border:2px solid #00dcb2;-webkit-text-fill-color:#00dcb2;-webkit-transition:background-color 5000s ease-in-out 0s;-o-transition:background-color 5000s ease-in-out 0s;transition:background-color 5000s ease-in-out 0s}._1cFKi fieldset p{background-color:#2e4b5f!important}._1cFKi fieldset button{margin-top:50px}._1cFKi .a1OxH{font-size:28.8px;font-size:1.8rem;display:block;line-height:normal;color:#ccf8ef}@media(max-width:480px){._1cFKi .a1OxH{font-size:1.4rem}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"loginForm": "_1cFKi",
	"subline": "a1OxH"
};