import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  defineMessages,
} from 'react-intl';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Alert, { alertOpts } from 'components/Alert';

import { DB_DATE_FORMAT } from '../../../util';
import RenewForm from './RenewForm';

const messages = defineMessages({
  close: {
    id: 'renewModal.close',
    defaultMessage: 'Close',
    description: 'Close Button label in Auto renew Modal',
  },
  modalHeader: {
    id: 'renewModal.modalHeader',
    defaultMessage: 'Renew Api Key',
    description: 'Header label for Auto renew Modal',
  },
  openRenew: {
    id: 'renewModal.openRenew',
    defaultMessage: 'Renew',
    description: 'Label for show AutoRenew button in apiKey table',
  },
  renewDate: {
    id: 'renewModal.renewDate',
    defaultMessage: 'Renew Date',
    description: 'Label for renewDate Date text in AutoRenew modal table',
  },
  result: {
    id: 'renewModal.result',
    defaultMessage: 'Result',
    description: 'Label for Result text in AutoRenew modal table',
  },
});

class RenewModalButton extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    apiKey: PropTypes.object.isRequired,
    validities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
      }).isRequired,
    ).isRequired,
    licenses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        duration: PropTypes.string.isRequired,
      }),
    ).isRequired,
    createValidity: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.closeAlert = this.closeAlert.bind(this);

    this.state = {
      isOpen: false,
      alert: {
        ...alertOpts.success,
      },
    };
  }

  onClose() {
    this.setState({ isOpen: false });
  }

  onOpen() {
    this.setState({ isOpen: true });
  }

  static getLastValidity(validities) {
    const lastValidities = validities.filter(validity => validity.enabled);
    if (lastValidities.length > 0) {
      return {
        endDate: moment(lastValidities[0].end, DB_DATE_FORMAT),
        licenseId: lastValidities[0].license.id,
      };
      // Tue Nov 27 2029 00:00:00 GMT+0100 (Central European Standard Time)
    }
    return {
      endDate: moment(),
      licenseId: null,
    };
  }

  closeAlert() {
    this.setState({ alert: { show: false } });
  }

  async handleSubmit(formValues) {
    const { createValidity, apiKey } = this.props;
    const validityData = {
      start: moment(formValues.startDate, DB_DATE_FORMAT),
      licenseId: formValues.license,
      apiKeyId: apiKey.id,
    };
    try {
      const createValidityResult = await createValidity({
        validity: validityData,
      });
      if (!createValidityResult.errors) {
        this.setState({ alert: { show: true } });
      } else {
        this.setState({
          alert: {
            ...alertOpts.error,
            show: true,
            msg: createValidityResult.errors[0].message,
          },
        });
      }
    } catch (e) {
      this.setState({
        alert: {
          ...alertOpts.error,
          show: true,
          msg: e.message,
        },
      });
    }
  }

  render() {
    const { isOpen, alert } = this.state;
    const { validities, licenses, apiKey } = this.props;
    const { endDate, licenseId } = RenewModalButton.getLastValidity(validities);
    return (
      <div>
        <button type="button" className="btn btn-primary" onClick={this.onOpen}>
          <FormattedMessage {...messages.openRenew} />
        </button>
        <Modal show={isOpen} backdrop>
          <Modal.Body>
            <div>
              <div>
                <div>
                  <h1>
                    <FormattedMessage {...messages.modalHeader} />
                  </h1>
                  <h3>{apiKey.name}</h3>
                  <br />
                </div>
              </div>
              <div style={{ marginBottom: '5px' }}>
                <RenewForm
                  onSubmit={this.handleSubmit}
                  initialValues={{ startDate: endDate, license: licenseId }}
                  licenses={licenses}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    this.onClose();
                  }}
                >
                  <FormattedMessage {...messages.close} />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Alert {...alert} onConfirm={this.closeAlert} />
      </div>
    );
  }
}

const createValidityQuery = gql`
  mutation createValidity($validity: ValidityInput!) {
    createValidity(validity: $validity) {
      id
      start
      license {
        id
      }
    }
  }
`;

export default compose(
  graphql(createValidityQuery, {
    props: ({ mutate }) => ({
      createValidity: ({ validity }) =>
        mutate({
          variables: { validity },
        }),
    }),
  }),
)(injectIntl(RenewModalButton));
