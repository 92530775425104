import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'dashboard.heading',
    defaultMessage: 'Welcome to your Cockpit',
    description: 'Heading for dashboard',
  },
  totalRequests: {
    id: 'dashboard.totalRequests',
    defaultMessage: 'Total Requests',
    description: 'Heading for number of total requests in dashboard',
  },
  status: {
    id: 'dashboard.status',
    defaultMessage: 'Status',
    description: 'Heading for Status search field in dashboard',
  },
  ok: {
    id: 'dashboard.ok',
    defaultMessage: 'OK',
    description: 'Heading for Status: OK',
  },
  unresponsive: {
    id: 'dashboard.unresponsive',
    defaultMessage: 'Unresponsive',
    description: 'Heading for Status: Unresponsive',
  },
});

export default messages;
