import { defineMessages } from 'react-intl';

const messages = defineMessages({
  username: {
    id: 'apiKey.username',
    defaultMessage: 'Username',
    description: 'Label for username in user',
  },
});

export default messages;
