/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import { Row, Col, Table } from 'react-bootstrap';
import Table2 from 'components/Table';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { HorizontalBar } from 'react-chartjs-2';
import gql from 'graphql-tag';
import FaExclamationTriangle from 'react-icons/lib/fa/exclamation-triangle';

import Loading from 'components/Loading';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import meQuery from 'components/Auth/queries/meQuery';

import { parseApiKeyStatisticFieldForValueName } from './utils';
import s from './Dashboard.scss';
import messages from './messages';

// import variables from '../../styles/base/variables.scss';
import Tabs from '../Tabs';
import ServerSelectionForm from './ServerSelectionForm';
import TimeSelectionForm from './TimeSelectionForm';
import ApiKeyStatisticsModal from './ApiKeyStatisticsLineChart/modal';

const LABEL_ALL = 'All';

const sortApiKeysByStatus = apiKeys => {
  if (!apiKeys) return [];

  return apiKeys.sort((apiKey1, apiKey2) => {
    const apiKey1Value = apiKey1.isUnresponsive ? 0 : 1;
    const apiKey2Value = apiKey2.isUnresponsive ? 0 : 1;
    return apiKey1Value - apiKey2Value;
  });
};

class Dashboard extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        username: PropTypes.string.isRequired,
      }),
    }).isRequired,
    keyQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      apiKeys: PropTypes.arrayOf(
        PropTypes.shape({
          licenseId: PropTypes.string,
          name: PropTypes.string.isRequired,
          description: PropTypes.string,
          enabled: PropTypes.bool.isRequired,
          validationInterval: PropTypes.number.isRequired,
        }),
      ),
    }).isRequired,
    lastValidationLogsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      lastSuccessfulValidationLogs: PropTypes.arrayOf(
        PropTypes.shape({
          apiKey: {
            id: PropTypes.string.isRequired,
          },
          createdAt: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    customerQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      customers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    statisticsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      apiKeyStatistics: PropTypes.arrayOf(
        PropTypes.shape({
          apiKeyId: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    cloudApiKeysQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      cloudApiKeys: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    intl: intlShape.isRequired,
  };

  static customFilter({ filter, onChange }) {
    const options = [
      { value: '', label: 'All' },
      { value: 'OK', label: 'OK' },
      { value: 'Unresponsive', label: 'Unresponsive' },
    ];
    return (
      <select
        options={options}
        onChange={event => {
          onChange(event.target.selectedOptions[0].value);
        }}
        value={filter ? filter.value : ''}
      >
        {options.map(option => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      currentServerSelection: null,
      timeSpan: 'Last Day',
      apiKeyStatisticsModal: {
        show: false,
        statistics: [],
        apiKey: null,
      },
    };

    this.renderMultiServerTab = this.renderMultiServerTab.bind(this);
    this.renderApiKeyTable = this.renderApiKeyTable.bind(this);
    this.renderServerTab = this.renderServerTab.bind(this);
    this.handleApiKeyStatisticsClick = this.handleApiKeyStatisticsClick.bind(
      this,
    );
    this.handleApiKeyStatisticsClose = this.handleApiKeyStatisticsClose.bind(
      this,
    );
  }

  getTrProps = (state, rowInfo) => {
    // eslint-disable-next-line no-underscore-dangle
    if (rowInfo && rowInfo.row._original.isUnresponsive) {
      return {
        style: {
          background: '#ffe6e6',
          color: 'black',
        },
      };
    }
    return {};
  };

  handleApiKeyStatisticsClick(statistics) {
    const { apiKeyId } = statistics[0];
    const { apiKeys } = this.props.keyQuery;
    if (apiKeys) {
      const apiKey = apiKeys.find(ak => ak.id === apiKeyId);
      this.setState({
        apiKeyStatisticsModal: { show: true, statistics, apiKey },
      });
    }
  }

  handleApiKeyStatisticsClose() {
    this.setState({
      apiKeyStatisticsModal: { show: false, statistics: [], apiKey: null },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderMultiServerTab(onPremiseServerData, logs) {
    if (!onPremiseServerData || onPremiseServerData.length === 0) {
      return <div />;
    }
    const { currentServerSelection } = this.state;

    const mergedServerData = {
      serverApiKeys: [],
      apiKeysOfServer: [],
      apiKeysIdsOfServer: [],
    };

    onPremiseServerData.forEach(serverData => {
      if (
        currentServerSelection === null ||
        currentServerSelection.length === 0 ||
        currentServerSelection.includes(serverData.serverApiKeyId)
      ) {
        mergedServerData.serverApiKeys = [
          ...mergedServerData.serverApiKeys,
          ...serverData.serverApiKeys,
        ];

        mergedServerData.apiKeysOfServer = [
          ...mergedServerData.apiKeysOfServer,
          ...serverData.apiKeysOfServer,
        ];

        mergedServerData.apiKeysIdsOfServer = [
          ...mergedServerData.apiKeysIdsOfServer,
          ...serverData.apiKeysIdsOfServer,
        ];
      }
    });

    return (
      <div>
        <Row>
          <Col md={10} xs={12}>
            <h3>Filter by server:</h3>
            <ServerSelectionForm
              servers={onPremiseServerData.map(serverData => ({
                id: serverData.serverApiKeyId,
                name: serverData.serverApiKeyName,
              }))}
              onSubmit={formValues => {
                this.setState({
                  currentServerSelection: formValues.server
                    ? formValues.server.map(ser => ser.value)
                    : null,
                });
              }}
            />
          </Col>
        </Row>
        {this.renderServerTab(mergedServerData, logs)}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderApiKeyTable({ apiKeys, logs, apiKeyStatisticValues }, tableHeader) {
    if (!apiKeys || apiKeys.length === 0) {
      return <div />;
    }
    const additionalLabels = new Set();
    if (apiKeyStatisticValues) {
      // loop through statistics per key
      Object.keys(apiKeyStatisticValues).forEach(key => {
        const apiKeyStatistics = apiKeyStatisticValues[key];
        if (apiKeyStatistics) {
          apiKeyStatistics.forEach(statistic => {
            if (statistic.statisticsValueName) {
              additionalLabels.add(statistic.statisticsValueName);
            }
          });
        }
      });
    }

    const additionalLabelsArray = Array.from(additionalLabels);

    const additionalColumns = additionalLabelsArray.map(label => ({
      id: label,
      Header: label,
      Cell: ({ original }) => {
        const apiKeyStatistics = apiKeyStatisticValues[original.id];
        if (apiKeyStatistics) {
          const displayValueForLabel = parseApiKeyStatisticFieldForValueName(
            apiKeyStatistics,
            label,
          );
          return (
            <a
              href="#"
              onClick={() => this.handleApiKeyStatisticsClick(apiKeyStatistics)}
            >
              {displayValueForLabel}
            </a>
          );
        }
        return '';
      },
      filterable: false,
    }));

    return (
      <div>
        {tableHeader}
        <Table2
          keyField="name"
          data={sortApiKeysByStatus(apiKeys).filter(apiKey => apiKey.enabled)}
          columns={[
            {
              id: '',
              Header: '',
              Cell: ({ original }) =>
                original.isUnresponsive && <FaExclamationTriangle />,
              width: 25,
              filterable: false,
            },
            {
              id: 'Api Key',
              Header: 'Api Key',
              Cell: ({ original }) => (
                <a href={`/app/apikey/${original.id}`}>{original.name}</a>
              ),
              filterMethod: (filter, row) =>
                row._original.name
                  .toLowerCase()
                  .includes(filter.value.toLowerCase()),
            },
            {
              id: 'Description',
              Header: 'Description',
              accessor: 'description',
              filterMethod: (filter, row) => {
                if (row._original.description) {
                  return row._original.description
                    .toLowerCase()
                    .includes(filter.value.toLowerCase());
                }
                return false;
              },
            },
            {
              id: 'Customer',
              Header: 'Customer',
              Cell: ({ original }) =>
                original.customer && (
                  <a href={`/app/customer/${original.customer.id}`}>
                    {original.customer.name}
                  </a>
                ),
              filterMethod: (filter, row) =>
                row._original.customer.name
                  .toLowerCase()
                  .includes(filter.value.toLowerCase()),
            },
            {
              id: 'Validation Interval',
              Header: 'Validation Interval',
              accessor: 'validationInterval',
              filterMethod: (filter, row) =>
                row._original.validationInterval
                  .toString()
                  .startsWith(filter.value),
            },
            {
              id: 'Last Validation Call',
              Header: 'Last Validation Call',
              Cell: ({ original }) =>
                original.id in logs
                  ? moment(logs[original.id]).format('DD.MM.YYYY HH:mm:ss')
                  : 'never',
              filterMethod: (filter, row) =>
                row._original.id in logs
                  ? moment(logs[row._original.id])
                      .format('DD.MM.YYYY HH:mm:ss')
                      .toString()
                      .includes(filter.value.toLowerCase())
                  : filter.value === '0',
            },
            {
              id: 'Status',
              Header: 'Status',
              Cell: ({ original }) =>
                original.isUnresponsive ? <span>Unresponsive</span> : 'OK',
              width: 150,
              filterMethod: (filter, row) =>
                (row._original.isUnresponsive &&
                  filter.value === 'Unresponsive') ||
                (!row._original.isUnresponsive && filter.value === 'OK') ||
                filter.value === '',
              Filter: ({ filter, onChange }) =>
                Dashboard.customFilter({ filter, onChange }),
            },
            ...additionalColumns,
          ]}
          getTrProps={this.getTrProps}
          filterable
        />
      </div>
    );
  }

  renderServerTab(
    { serverApiKeys, apiKeysOfServer, apiKeysIdsOfServer },
    logs,
  ) {
    if (
      apiKeysOfServer &&
      apiKeysOfServer.length === 0 &&
      (serverApiKeys && serverApiKeys.length === 0)
    ) {
      return <div />;
    }
    const { customerQuery, statisticsQuery } = this.props;

    const customerForApiKey = {};
    const overusedStatistics = [];
    const apiKeysById = {};

    const predictionStatistics = {};
    const recommendationStatistics = {};
    const apiKeyStatisticValues = {};

    // compare log entries with validation interval of each api key
    [...serverApiKeys, ...apiKeysOfServer].forEach(serverApiKey => {
      // eslint-disable-next-line no-param-reassign
      serverApiKey.isUnresponsive = false;

      if (serverApiKey.enabled) {
        const intervalStart = new Date();
        intervalStart.setDate(
          intervalStart.getDate() - serverApiKey.validationInterval,
        );
        if (
          !(serverApiKey.id in logs) ||
          logs[serverApiKey.id] < intervalStart
        ) {
          // eslint-disable-next-line no-param-reassign
          serverApiKey.isUnresponsive = true;
        }
      }
    });

    // add customer information to keys
    [...serverApiKeys, ...apiKeysOfServer].forEach(key => {
      customerQuery.customers.forEach(customer => {
        customer.billingData.forEach(billingData => {
          // eslint-disable-next-line no-param-reassign
          if (key.billingData.id === billingData.id) key.customer = customer;
        });
      });
    });

    customerQuery.customers.forEach(customer => {
      customer.billingData.forEach(billingData => {
        [...serverApiKeys, ...apiKeysOfServer].forEach(apiKey => {
          if (apiKey.enabled) {
            // eslint-disable-next-line no-param-reassign
            if (apiKey.billingData.id === billingData.id)
              customerForApiKey[apiKey.id] = customer;
            apiKeysById[apiKey.id] = apiKey;
          }
        });
      });
    });

    const today = new Date();

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const before30Days = new Date();
    before30Days.setDate(before30Days.getDate() - 30);
    statisticsQuery.apiKeyStatistics.forEach(statistic => {
      if (!apiKeysIdsOfServer.includes(statistic.apiKeyId)) return;

      if (!apiKeyStatisticValues[statistic.apiKeyId]) {
        apiKeyStatisticValues[statistic.apiKeyId] = [statistic];
      } else {
        apiKeyStatisticValues[statistic.apiKeyId].push(statistic);
      }

      const statisticDate = new Date(
        statistic.date.substring(0, 4),
        (parseInt(statistic.date.substring(5, 7), 10) - 1).toString(),
        statistic.date.substring(8, 10),
      );

      if (
        (this.state.timeSpan === 'Last Day' &&
          statisticDate.getFullYear() === yesterday.getFullYear() &&
          statisticDate.getMonth() === yesterday.getMonth() &&
          statisticDate.getDate() === yesterday.getDate()) ||
        (this.state.timeSpan === 'Last 30 Days' &&
          statisticDate >= before30Days) ||
        (this.state.timeSpan === 'Last Year' &&
          statisticDate.getFullYear() === today.getFullYear() - 1) ||
        (this.state.timeSpan === 'This Month' &&
          statisticDate.getFullYear() === today.getFullYear() &&
          statisticDate.getMonth() === today.getMonth()) ||
        (this.state.timeSpan === 'This Year' &&
          statisticDate.getFullYear() === today.getFullYear())
      ) {
        const customer = customerForApiKey[statistic.apiKeyId].name;

        if (statistic.endpoint === 'PREDICTION') {
          if (customer in predictionStatistics) {
            predictionStatistics[customer] += statistic.count;
          } else {
            predictionStatistics[customer] = statistic.count;
          }

          if (LABEL_ALL in predictionStatistics) {
            predictionStatistics[LABEL_ALL] += statistic.count;
          } else {
            predictionStatistics[LABEL_ALL] = statistic.count;
          }
        } else if (statistic.endpoint === 'RECOMMENDATION') {
          if (customer in recommendationStatistics) {
            recommendationStatistics[customer] += statistic.count;
          } else {
            recommendationStatistics[customer] = statistic.count;
          }

          if (LABEL_ALL in recommendationStatistics) {
            recommendationStatistics[LABEL_ALL] += statistic.count;
          } else {
            recommendationStatistics[LABEL_ALL] = statistic.count;
          }
        }
      }
    });

    let labels = [];
    let dataSet = [];
    Object.keys(predictionStatistics).forEach(key => {
      if (key !== LABEL_ALL) {
        labels.push(key);
        dataSet.push(predictionStatistics[key]);
      }
    });
    const horizontalBarPrediction = {
      labels,
      datasets: [
        {
          label: '',
          backgroundColor: 'rgba(0,220,178,0.2)',
          borderColor: 'rgba(91,189,170,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(91,189,170,0.4)',
          hoverBorderColor: 'rgba(91,189,170,1)',
          data: dataSet,
        },
      ],
    };

    labels = [];
    dataSet = [];
    Object.keys(recommendationStatistics).forEach(key => {
      if (key !== LABEL_ALL) {
        labels.push(key);
        dataSet.push(recommendationStatistics[key]);
      }
    });
    const horizontalBarRecommendation = {
      labels,
      datasets: [
        {
          label: '',
          backgroundColor: 'rgba(0,220,178,0.2)',
          borderColor: 'rgba(91,189,170,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(91,189,170,0.4)',
          hoverBorderColor: 'rgba(91,189,170,1)',
          data: dataSet,
        },
      ],
    };

    const horizontalBarChartMinHeight = 50; // extra height for padding + legend
    return (
      <div>
        <Row>
          <Col md={10} xs={12}>
            {this.renderApiKeyTable(
              { apiKeys: serverApiKeys, logs },
              <h3>Server API Keys:</h3>,
            )}

            {this.renderApiKeyTable(
              {
                apiKeys: apiKeysOfServer,
                logs,
                apiKeyStatisticValues,
              },
              <h3>API Keys:</h3>,
            )}
          </Col>
        </Row>
        <Row>
          <Col md={10} xs={12}>
            <h3>Filter by timespan:</h3>
            <TimeSelectionForm
              onSubmit={formValues => {
                if (formValues.time) {
                  this.setState({
                    timeSpan: formValues.time.value,
                  });
                }
              }}
              initialValues={{ time: { value: 'Last Day', label: 'Last Day' } }}
            />
          </Col>
        </Row>
        <h3>Prediction</h3>
        {Object.keys(predictionStatistics).length > 0 && (
          <div>
            <FormattedMessage {...messages.totalRequests} />:
            <span> {predictionStatistics[LABEL_ALL] || 0}</span>
            <Row>
              <Col md={10} xs={12}>
                <div
                  style={{
                    height:
                      horizontalBarChartMinHeight +
                      horizontalBarPrediction.labels.length * 30,
                  }}
                >
                  <HorizontalBar
                    data={horizontalBarPrediction}
                    options={{
                      legend: false,
                      maintainAspectRatio: false,
                      scales: {
                        xAxes: [{ ticks: { beginAtZero: true, min: 0 } }],
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}
        <h3>Recommendation</h3>

        {Object.keys(recommendationStatistics).length > 0 && (
          <div>
            <FormattedMessage {...messages.totalRequests} />:
            <span> {recommendationStatistics[LABEL_ALL] || 0}</span>
            <Row>
              <Col md={10} xs={12}>
                <div
                  style={{
                    height:
                      horizontalBarChartMinHeight +
                      horizontalBarRecommendation.labels.length * 30,
                  }}
                >
                  <HorizontalBar
                    data={horizontalBarRecommendation}
                    options={{
                      legend: false,
                      maintainAspectRatio: false,
                      scales: {
                        xAxes: [{ ticks: { beginAtZero: true, min: 0 } }],
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}
        <h3>Overused Api Keys</h3>
        <Row>
          <Col md={10} xs={12}>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Api Key</th>
                  <th>Customer</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {overusedStatistics.map(statistic => {
                  const statusStyle = { backgroundColor: '#ffe6e6' };
                  if (
                    statistic.count >
                    apiKeysById[statistic.apiKeyId].license.credits
                  ) {
                    return (
                      <tr>
                        <td style={statusStyle}>
                          <FaExclamationTriangle />{' '}
                          <a href={`/app/apikey/${statistic.apiKeyId}`}>
                            {apiKeysById[statistic.apiKeyId].name}
                          </a>
                        </td>
                        <td style={statusStyle}>
                          {statistic.customer && (
                            <a
                              href={`/app/customer/${customerForApiKey[statistic.apiKeyId].id}`}
                            >
                              {statistic.customer}
                            </a>
                          )}
                        </td>
                        <td style={statusStyle}>
                          {statistic.count} /{' '}
                          {apiKeysById[statistic.apiKeyId].license.credits}
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }

  // eslint-disable-next-line react/sort-comp
  render() {
    const {
      data: { loading },
      keyQuery,
      lastValidationLogsQuery,
      customerQuery,
      statisticsQuery,
      cloudApiKeysQuery,
    } = this.props;

    if (
      loading ||
      keyQuery.loading ||
      lastValidationLogsQuery.loading ||
      customerQuery.loading ||
      statisticsQuery.loading ||
      cloudApiKeysQuery.loading
    ) {
      return <Loading />;
    }

    const logs = [];
    // collect last log entries for each api key
    lastValidationLogsQuery.lastSuccessfulValidationLogs.forEach(log => {
      const newestDate = moment(log.createdAt, 'DD.MM.YYYY HH:mm:ss').toDate();
      logs[log.apiKey.id] = newestDate;
    });

    const { apiKeys } = keyQuery;

    // server api keys
    const serverApiKeysIds = [];
    const serverApiKeys = [];
    // api keys sorted by server
    const apiKeysIdsSortedByServer = { 'No Server': [] };
    const apiKeysSortedByServer = { 'No Server': [] };

    // get all server api keys
    apiKeys.forEach(apiKey => {
      // check if cloud server
      if (cloudApiKeysQuery.cloudApiKeys.includes(apiKey.id)) {
        serverApiKeysIds.push(apiKey.id);
        serverApiKeys.push(apiKey);
      } else if (apiKey.license && apiKey.license.products) {
        let isOnPremises = false;
        apiKey.license.products.forEach(product => {
          if (product.isOnPremises) isOnPremises = true;
        });
        if (isOnPremises) {
          serverApiKeysIds.push(apiKey.id);
          serverApiKeys.push(apiKey);
        }
      }
    });

    // sort all other keys by their according server.
    statisticsQuery.apiKeyStatistics.forEach(statistic => {
      const { serverApiKeyId, apiKeyId } = statistic;
      const apiKey = apiKeys.find(ak => ak.id === apiKeyId);
      apiKey.statistic = statistic;
      if (!apiKeysIdsSortedByServer[serverApiKeyId]) {
        // fill api key to server maps
        apiKeysIdsSortedByServer[serverApiKeyId] = [apiKeyId];
        apiKeysSortedByServer[serverApiKeyId] = [
          apiKeys.find(ak => ak.id === apiKeyId),
        ];
      } // fill api key to server maps
      else if (!apiKeysIdsSortedByServer[serverApiKeyId].includes(apiKeyId)) {
        apiKeysIdsSortedByServer[serverApiKeyId].push(apiKeyId);
        apiKeysSortedByServer[serverApiKeyId].push(
          apiKeys.find(ak => ak.id === apiKeyId),
        );
      }
    });

    // split servers in 'Cloud' (danube server), 'On Premise' (all other servers) and 'No Server'
    // get 'Cloud' server data
    const cloudServerData = [];
    serverApiKeys.forEach(serverApiKey => {
      if (cloudApiKeysQuery.cloudApiKeys.includes(serverApiKey.id)) {
        if (
          apiKeysSortedByServer[serverApiKey.id] &&
          apiKeysSortedByServer[serverApiKey.id].length > 0 &&
          apiKeysIdsSortedByServer[serverApiKey.id] &&
          apiKeysIdsSortedByServer[serverApiKey.id].length > 0
        ) {
          cloudServerData.push({
            serverApiKeyId: serverApiKey.id,
            serverApiKeyName: serverApiKey.name,
            serverApiKeys: [serverApiKey],
            apiKeysOfServer: apiKeysSortedByServer[serverApiKey.id],
            apiKeysIdsOfServer: apiKeysIdsSortedByServer[serverApiKey.id],
          });
        } else {
          cloudServerData.push({
            serverApiKeyId: serverApiKey.id,
            serverApiKeyName: serverApiKey.name,
            serverApiKeys: [serverApiKey],
            apiKeysOfServer: [],
            apiKeysIdsOfServer: [],
          });
        }
      }
    });

    // find all api keys not mapped to a server and add them to 'No Server'
    apiKeys.forEach(apiKey => {
      let foundServer = false;
      Object.keys(apiKeysIdsSortedByServer).forEach(serverApiKey => {
        if (
          apiKeysIdsSortedByServer[serverApiKey].includes(apiKey.id) ||
          cloudApiKeysQuery.cloudApiKeys.includes(apiKey.id)
        ) {
          foundServer = true;
        }
      });
      if (!foundServer) {
        apiKeysIdsSortedByServer['No Server'].push(apiKey.id);
        apiKeysSortedByServer['No Server'].push(apiKey);
      }
    });

    // get 'On Premise' server data
    const onPremiseServerData = [];
    serverApiKeys.forEach(serverApiKey => {
      if (
        !cloudApiKeysQuery.cloudApiKeys.includes(serverApiKey.id) &&
        serverApiKey.id !== 'No Server'
      ) {
        if (
          apiKeysSortedByServer[serverApiKey.id] &&
          apiKeysSortedByServer[serverApiKey.id].length > 0 &&
          apiKeysIdsSortedByServer[serverApiKey.id] &&
          apiKeysIdsSortedByServer[serverApiKey.id].length > 0
        ) {
          onPremiseServerData.push({
            serverApiKeyId: serverApiKey.id,
            serverApiKeyName: serverApiKey.name,
            serverApiKeys: [serverApiKey],
            apiKeysOfServer: apiKeysSortedByServer[serverApiKey.id],
            apiKeysIdsOfServer: apiKeysIdsSortedByServer[serverApiKey.id],
          });
        } else {
          onPremiseServerData.push({
            serverApiKeyId: serverApiKey.id,
            serverApiKeyName: serverApiKey.name,
            serverApiKeys: [serverApiKey],
            apiKeysOfServer: [],
            apiKeysIdsOfServer: [],
          });
        }
      }
    });

    // get 'No Server' server data
    const noServerData = {
      serverApiKeys: [],
      apiKeysOfServer: apiKeysSortedByServer['No Server'] || [],
      apiKeysIdsOfServer: apiKeysIdsSortedByServer['No Server'] || [],
    };

    return (
      <div>
        <Tabs
          tabTitles={['Cloud', 'On-Premise', 'No Server']}
          active={[
            cloudServerData.length > 0,
            onPremiseServerData.length > 0,
            noServerData.apiKeysOfServer &&
              noServerData.apiKeysOfServer.length > 0,
          ]}
        >
          {this.renderMultiServerTab(cloudServerData, logs)}
          {this.renderMultiServerTab(onPremiseServerData, logs)}
          {this.renderServerTab(noServerData, logs)}
        </Tabs>
        <ApiKeyStatisticsModal
          show={this.state.apiKeyStatisticsModal.show}
          statistics={this.state.apiKeyStatisticsModal.statistics}
          apiKey={this.state.apiKeyStatisticsModal.apiKey}
          onClose={this.handleApiKeyStatisticsClose}
        />
      </div>
    );
  }
}

const apiKeysQuery = gql`
  query keyQuery {
    apiKeys {
      id
      license {
        id
        credits
        products {
          id
          isOnPremises
        }
      }
      billingData {
        id
      }
      name
      description
      enabled
      validationInterval
    }
  }
`;

const customersQuery = gql`
  query customerQuery {
    customers {
      id
      name
      billingData {
        id
      }
    }
  }
`;

const lastValidationLogsQuery = gql`
  query lastValidationLogsQuery {
    lastSuccessfulValidationLogs {
      apiKey {
        id
      }
      createdAt
    }
  }
`;

const apiKeyStatisticsQuery = gql`
  query statisticsQuery {
    apiKeyStatistics {
      id
      apiKeyId
      serverApiKeyId
      endpoint
      date
      count
      statisticsValue
      statisticsValueName
      statisticsValueUnit
    }
  }
`;

const cloudApiKeysQuery = gql`
  query cloudApiKeysQuery {
    cloudApiKeys
  }
`;

export default compose(
  graphql(meQuery, {
    name: 'data',
  }),
  graphql(apiKeysQuery, {
    name: 'keyQuery',
  }),
  graphql(lastValidationLogsQuery, {
    name: 'lastValidationLogsQuery',
  }),
  graphql(customersQuery, {
    name: 'customerQuery',
  }),
  graphql(apiKeyStatisticsQuery, {
    name: 'statisticsQuery',
  }),
  graphql(cloudApiKeysQuery, {
    name: 'cloudApiKeysQuery',
  }),
)(AuthenticationWrapper(injectIntl(withStyles(s)(Dashboard))));
