import { defineMessages } from 'react-intl';

const messages = defineMessages({
  actions: {
    id: 'table.actions',
    defaultMessage: 'Actions',
    description: 'Label for actions column in tables',
  },
});

export default messages;
