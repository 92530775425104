import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Panel, PanelGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaFolder from 'react-icons/lib/fa/folder';
import FaFolderOpen from 'react-icons/lib/fa/folder-open';
import FaChevronDown from 'react-icons/lib/fa/chevron-down';
import FaChevronLeft from 'react-icons/lib/fa/chevron-left';
import FaChevronRight from 'react-icons/lib/fa/chevron-right';
import { FormattedMessage } from 'react-intl';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import gate from 'components/Auth/gate';
import roles from 'core/roles';
import messages from './messages';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Sidebar.scss';

const navTree = [
  {
    name: messages.dashboard,
    subNavs: [
      {
        name: messages.dashboard,
        path: '/app/dashboard',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.user,
    users: [roles.ADMIN],
    subNavs: [
      {
        name: messages.user,
        path: '/app/user',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.role,
    roles: [roles.ADMIN],
    subNavs: [
      {
        name: messages.role,
        path: '/app/role',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.product,
    roles: [roles.ADMIN],
    subNavs: [
      {
        name: messages.product,
        path: '/app/product',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.license,
    roles: [roles.ADMIN],
    subNavs: [
      {
        name: messages.license,
        path: '/app/license',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.customer,
    roles: [roles.ADMIN],
    subNavs: [
      {
        name: messages.customer,
        path: '/app/customer',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.apikey,
    roles: [roles.ADMIN],
    subNavs: [
      {
        name: messages.apikey,
        path: '/app/apikey',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
    showAdd: true,
  },
  {
    name: messages.statistics,
    roles: [roles.VIEW_DASHBOARD_STATISTICS],
    subNavs: [
      {
        name: messages.statistics,
        path: '/app/statistics',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
    showAdd: true,
  },
];

class Sidebar extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
      userRoles: PropTypes.array,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      activeKey: null,
    };
  }

  handleSelect(activeKey) {
    if (activeKey !== null) {
      const nav = navTree[activeKey];

      if (nav && nav.subNavs.length === 1) {
        const { history } = this.props;
        history.push({ pathname: nav.subNavs[0].path });
      }
    }
    this.setState({ activeKey });
  }

  render() {
    const { me } = this.props;
    return (
      <PanelGroup
        className={s.sideNav}
        accordion
        id="sidebar-nav"
        activeKey={this.state.activeKey}
        onSelect={this.handleSelect}
      >
        {navTree.map((nav, index) => {
          if (typeof nav === 'function') {
            // eslint-disable-next-line no-param-reassign
            nav = nav(me);
          }
          const showNav =
            (!nav.roles ||
              gate.isAllowed({ user: me, allowedRoles: nav.roles })) &&
            (!nav.filter || nav.filter(this.props.me));
          if (!showNav) return null;

          const key = `nav_${index}`;
          const Icon =
            this.state.activeKey === index ? nav.openIcon : nav.closedIcon;
          const IconChevron =
            this.state.activeKey === index ? FaChevronDown : FaChevronLeft;
          const navTitle =
            this.state.activeKey === index ||
            this.props.location.pathname === nav.subNavs[0].path
              ? s.navTitleSelected
              : s.navTitle;

          return (
            <Panel className={s.navPanel} key={key} eventKey={index}>
              <Panel.Heading className={s.navHeading}>
                <Panel.Title className={navTitle} toggle>
                  <Icon className="fa--prepended" />{' '}
                  <span>
                    <FormattedMessage {...nav.name} />
                  </span>
                  {nav.subNavs.length > 1 && (
                    <IconChevron className={s.iconRight} />
                  )}
                </Panel.Title>
              </Panel.Heading>
              {nav.subNavs.length > 1 && ( // only render sub panel if it contains more than 1 entry
                <Panel.Body className={s.navBody} collapsible>
                  {nav.subNavs
                    .filter(
                      subNav =>
                        !subNav.roles ||
                        subNav.roles.every(r => me.userRoles.includes(r)),
                    )
                    .map((subNav, subIndex) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className={s.subNav} key={`subNav_${subIndex}`}>
                        <Link to={subNav.path}>
                          <FaChevronRight className="fa--prepended" />
                          <FormattedMessage {...subNav.name} />
                        </Link>
                      </div>
                    ))}
                </Panel.Body>
              )}
            </Panel>
          );
        })}
      </PanelGroup>
    );
  }
}

export default AuthenticationWrapper(withStyles(s)(Sidebar));
