import gql from 'graphql-tag';

// parse attribute list for graphql query
// e.g. attributes = ["id", "description", { licenses: ["id", "name"]]
// would return:
// id
// description
// licenses {
//  id
//  name
// }
function getAttributeList(attributes) {
  return attributes
    .map(attribute => {
      if (typeof attribute === 'object') {
        const modelName = Object.keys(attribute)[0];
        return `${modelName} {
          ${getAttributeList(attribute[modelName])}
        }`;
      }
      return attribute;
    })
    .join('\n');
}

// e.g. agumentObject = { id: "String!" }
// returns (id: $id)
function parseArguments(argumentObject) {
  if (!argumentObject) {
    return '';
  }
  const argumentsString = Object.keys(argumentObject)
    .map(key => `${key}: $${key}`)
    .join(', ');

  return `(${argumentsString})`;
}

// Arguments are passed as an object with the attribute name as key and type as value
// e.g. argumentObejct = { id: "String!" }
// return ($id: String!)
function parseArgumentTypes(argumentObject) {
  if (!argumentObject) {
    return '';
  }
  const argumentsString = Object.keys(argumentObject)
    .map(key => {
      const val = argumentObject[key];
      return `$${key}: ${val.type}`;
    })
    .join(', ');

  return `(${argumentsString})`;
}

function getModelMutation({ modelName, attributes, operation }) {
  // make first char uppercase
  const queryModelName = modelName.charAt(0).toUpperCase() + modelName.slice(1);
  const argumentTypeString = `($${modelName}: ${queryModelName}Input!)`;
  const argumentString = `(${modelName}: $${modelName})`;
  const mutationString = `
    mutation ${operation}${queryModelName}${argumentTypeString} {
      ${operation}${queryModelName}${argumentString} {
      ${getAttributeList(attributes)}
      }
    }`;
  return gql`
    ${mutationString}
  `;
}

// build simple graphql query from modelName and given attributes
// e.g. attributes = ["id", "name", { subModel: ["id", "name"] }]
// models can be nested more than one level
function getModelQuery({ modelName, attributes, argumentObject }) {
  // keep camelcasing, but make first char lowercase
  const argumentTypeString = parseArgumentTypes(argumentObject);
  const argumentString = parseArguments(argumentObject);
  const queryString = `
    query ${modelName}Query${argumentTypeString} {
      ${modelName}${argumentString} {
      ${getAttributeList(attributes)}
      }
    }`;
  return gql`
    ${queryString}
  `;
}

const queryGenerator = {
  getAttributeList,
  getModelQuery,
  getModelMutation,
};

export default queryGenerator;
