import React from 'react';
import PropTypes from 'prop-types';

import CrudView from 'components/Crud/CrudView';
import productMessages from './messages';

class ProductView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };
  render() {
    const modelQueryAttributes = [
      'id',
      'key',
      'name',
      'shortDescription',
      'description',
      'productVersionLifespan',
      'isOnPremises',
    ];

    const viewAttributes = {
      key: { type: 'text', required: true },
      name: { type: 'text', required: true },
      shortDescription: { type: 'text', required: false },
      description: { type: 'text', required: false },
      productVersionLifespan: { type: 'text', required: true },
      isOnPremises: { type: 'boolean', required: true },
    };
    const messages = {
      messageList: productMessages,
    };
    return (
      <CrudView
        headerName="Product"
        modelName="product"
        modelQueryAttributes={modelQueryAttributes}
        messages={messages}
        viewAttributes={viewAttributes}
        match={this.props.match}
      />
    );
  }
}

export default ProductView;
