/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Row } from 'react-bootstrap';
import _ from 'lodash';
import FaTrash from 'react-icons/lib/fa/trash';

import formMessages from 'components/ReduxForm/messages';
import render from 'components/ReduxForm/renderField';
import CrudView from './CrudView';
import validations from '../ReduxForm/validations';

const validate = (values, props) => {
  const errors = {};
  const { viewAttributes } = props;

  // check if required attributes in viewAttributes are set
  Object.keys(viewAttributes).forEach(key => {
    const column = viewAttributes[key].columnName || key;
    const val = values[column];
    const { type } = viewAttributes[key];
    if (viewAttributes[key].required && val === undefined) {
      errors[column] = <FormattedMessage {...formMessages.required} />;
    }

    if (type === 'object') {
      try {
        JSON.parse(val);
      } catch (error) {
        errors[column] = <FormattedMessage {...formMessages.invalidJson} />;
      }
    } else if (type === 'float' && val !== undefined && !errors[column]) {
      if (!validations.validNumber(val)) {
        errors[column] = <FormattedMessage {...formMessages.numberTooLarge} />;
      } else if (val < 0) {
        errors[column] = <FormattedMessage {...formMessages.negativeNumber} />;
      }
    } else if (type === 'int' && val !== undefined && !errors[column]) {
      if (!validations.validNumber(val)) {
        errors[column] = <FormattedMessage {...formMessages.numberTooLarge} />;
      } else if (!validations.validInt(val)) {
        errors[column] = <FormattedMessage {...formMessages.notInteger} />;
      } else if (val < 0) {
        errors[column] = <FormattedMessage {...formMessages.negativeNumber} />;
      }
    }
  });
  return errors;
};

class CrudViewForm extends React.Component {
  static contextTypes = {
    client: PropTypes.object,
  };

  static propTypes = {
    intl: intlShape.isRequired,
    headerName: PropTypes.string.isRequired,
    modelName: PropTypes.string.isRequired,
    modelQueryAttributes: PropTypes.array.isRequired,
    modelQueryResult: PropTypes.object,
    messages: PropTypes.object.isRequired,
    viewAttributes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    modelId: PropTypes.string,
    parentEntryId: PropTypes.string,
    isSubForm: PropTypes.bool,
    joinedTable: PropTypes.bool,
    // redux form attributes to remove correct form in recursion
    fields: PropTypes.object,
    fieldIndex: PropTypes.number,
  };

  static defaultProps = {
    joinedTable: null,
    modelQueryResult: null,
    modelId: null,
    parentEntryId: null,
    isSubForm: false,
    fields: null,
    fieldIndex: 0,
  };

  constructor(props) {
    super(props);

    this.renderFields = this.renderFields.bind(this);
    this.generateSubFormProps = this.generateSubFormProps.bind(this);
  }

  static renderSubForm(props, fields, index) {
    const modelId = props.subFormData[index]
      ? props.subFormData[index].id
      : null;
    const newProps = {
      ..._.omit(props, ['subFormData']),
      modelId,
      subFormData: props.subFormData[index],
      fields,
      fieldIndex: index,
    };
    return <CrudView {...newProps} />;
  }

  generateSubFormProps(attributeKey) {
    const { modelQueryAttributes, modelId, modelQueryResult } = this.props;
    const subViewAttributes = this.props.viewAttributes[attributeKey]
      .viewAttributes;
    // find attribute in array
    // if an alternative queryAttribute is defined in viewAttributes - use this one to find the query attribute
    // in case of singular plural mismatches between queries
    const modelAttributeKey = this.props.viewAttributes[attributeKey]
      .queryAttribute
      ? this.props.viewAttributes[attributeKey].queryAttribute
      : attributeKey;

    const subModelQueryAttributes = modelQueryAttributes.find(
      att =>
        typeof att === 'object' &&
        (Object.keys(att)[0] === modelAttributeKey ||
          att.joinTable === modelAttributeKey),
    );
    let relationTable = null;
    // get correct table name from n to n
    if (subModelQueryAttributes.joinAttributes) {
      relationTable =
        subViewAttributes[Object.keys(subViewAttributes)[0]].query;
    }
    const props = {
      headerName: attributeKey,
      modelName: attributeKey,
      modelQueryAttributes:
        subModelQueryAttributes.joinAttributes ||
        subModelQueryAttributes[modelAttributeKey],
      messages: this.props.messages[attributeKey],
      viewAttributes: subViewAttributes,
      parentEntryId: modelId,
      parentColumn: subModelQueryAttributes.parentColumn,
      isSubForm: true,
      joinedTable: relationTable ? Object.keys(subViewAttributes)[0] : null,
      subFormData: modelQueryResult
        ? modelQueryResult[relationTable || modelAttributeKey]
        : [],
    };
    return props;
  }

  renderFields() {
    const {
      handleSubmit,
      handleDelete,
      viewAttributes,
      messages,
      fields,
      fieldIndex,
      isSubForm,
      intl,
      parentEntryId,
      modelId,
    } = this.props;
    return [
      <form onSubmit={handleSubmit}>
        <fieldset>
          {Object.keys(viewAttributes)
            .filter(a => viewAttributes[a].type !== 'model')
            .map(key => {
              const viewAttribute = viewAttributes[key];
              let component = '';
              let selectOptions = null;
              let fieldType = viewAttribute.type;
              const additionalProps = {};
              if (
                viewAttribute.type === 'text' ||
                viewAttribute.type === 'object'
              ) {
                component = render.renderInput;
              } else if (
                viewAttribute.type === 'int' ||
                viewAttribute.type === 'float'
              ) {
                component = render.renderInput;
                fieldType = 'number';
              } else if (viewAttribute.type === 'date') {
                component = render.renderDate;
              } else if (viewAttribute.type === 'selectModel') {
                if (Array.isArray(viewAttribute.viewAttribute)) {
                  component = render.renderMultilineSelect;
                  selectOptions = viewAttribute.queryResult.map(entry => {
                    const categoryLabels = Object.keys(entry)
                      .filter(
                        categoryKey =>
                          typeof entry[categoryKey] === 'object' &&
                          entry[categoryKey] !== null,
                      )
                      .map((categoryKey, idx) => {
                        const cat =
                          viewAttribute.viewAttribute[idx][categoryKey][0];
                        return entry[categoryKey][cat];
                      });
                    const subDisplayValue = [];
                    const subDisplayValuePreview = [];
                    viewAttribute.viewAttribute.forEach((subAttribute, i) => {
                      if (i !== 0) {
                        const attributeType = viewAttribute.attributeTypes
                          ? viewAttribute.attributeTypes[i]
                          : null;
                        let value = entry[subAttribute];
                        if (attributeType === 'boolean' && value !== true) {
                          value = false;
                        }
                        subDisplayValue.push(`${subAttribute}: ${value} `);
                        subDisplayValuePreview.push(
                          <span>
                            <b>{subAttribute}: </b>
                            {value != null && value !== '' ? (
                              <span style={{ color: 'black' }}>
                                {`${value}`}
                              </span>
                            ) : (
                              <span style={{ color: 'lightgrey' }}>null</span>
                            )}
                            {i === viewAttribute.viewAttribute.length - 1
                              ? ''
                              : ', '}
                          </span>,
                        );
                      }
                    });
                    let categoryLabel = '';
                    if (categoryLabels.length === 1)
                      categoryLabel = categoryLabels[0];
                    else {
                      categoryLabel = entry[viewAttribute.viewAttribute[0]];
                    }
                    return {
                      value: entry.id,
                      label: [categoryLabel, ...subDisplayValue],
                      controlLabel: (
                        <span>
                          <b>{categoryLabel} | </b>
                          {subDisplayValuePreview}
                        </span>
                      ),
                    };
                  });
                } else {
                  component = render.renderSelect;
                  selectOptions = viewAttribute.queryResult.map(entry => {
                    let displayValue = '';
                    displayValue = entry[viewAttribute.viewAttribute];
                    return (
                      <option key={entry.id} value={entry.id}>
                        {displayValue}
                      </option>
                    );
                  });
                }

                if (Array.isArray(viewAttribute.viewAttribute)) {
                  additionalProps.options = selectOptions;
                } else {
                  selectOptions.unshift(<option value="" />);
                }
              } else if (viewAttribute.type === 'enum') {
                component = render.renderSelect;
                selectOptions = viewAttribute.options.map((option, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option key={index} value={option}>
                    {intl.formatMessage(
                      messages.messageList[
                        `${option.charAt(0).toLowerCase()}${option.slice(1)}`
                      ],
                    )}
                  </option>
                ));
              } else if (viewAttribute.type === 'boolean') {
                component = render.renderSelect;
                selectOptions = [
                  <option key="true" value>
                    {intl.formatMessage(formMessages.yes)}
                  </option>,
                  <option key="false" value={false}>
                    {intl.formatMessage(formMessages.no)}
                  </option>,
                ];
              }
              if (component) {
                let fieldKey = viewAttribute.columnName || key;
                if (this.props.joinedTable) {
                  fieldKey = 'id';
                }
                return (
                  <Field
                    disabled={viewAttribute.readOnly}
                    key={fieldKey}
                    name={fieldKey}
                    type={fieldType}
                    label={<FormattedMessage {...messages.messageList[key]} />}
                    component={component}
                    {...additionalProps}
                  >
                    {selectOptions}
                  </Field>
                );
              }
              return (
                <b>
                  Error: Could not build Input for type: {viewAttribute.type}
                  <br />
                </b>
              );
            })}
          {/* show save button only if model not created yet or
           if not a n:m joined table (no id present to update existing entry in this specific case) */}
          {(!this.props.joinedTable || !modelId) && (
            <button
              type="button"
              onClick={this.props.handleSubmit}
              className="btn btn-primary"
            >
              <FormattedMessage {...formMessages.submit} />
            </button>
          )}
          {isSubForm && (
            <button
              type="button"
              className={['btn btn-danger']}
              onClick={async () => {
                if (handleDelete) {
                  await handleDelete();
                  fields.remove(fieldIndex);
                }
              }}
            >
              <FaTrash />
              <FormattedMessage {...formMessages.remove} />
            </button>
          )}
        </fieldset>
      </form>,
      <fieldset>
        {Object.keys(viewAttributes)
          .filter(a => viewAttributes[a].type === 'model')
          .map(key => {
            if (!parentEntryId) {
              // render fieldArray only when editing parent
              return <div />;
            }
            const modelProps = {
              customProps: this.generateSubFormProps(key),
            };
            const modelAttributeKey = viewAttributes[key].queryAttribute
              ? viewAttributes[key].queryAttribute
              : key;
            let fieldArrayKey = key;
            if (modelProps.customProps.joinedTable) {
              fieldArrayKey =
                viewAttributes[key].viewAttributes[
                  Object.keys(viewAttributes[key].viewAttributes)[0]
                ].query;
            } else if (viewAttributes[key].queryAttribute) {
              fieldArrayKey = viewAttributes[key].queryAttribute;
            }
            return (
              <FieldArray
                name={fieldArrayKey}
                key={fieldArrayKey}
                handleDelete={handleDelete}
                component={render.renderGenericFieldArray}
                renderField={CrudViewForm.renderSubForm}
                props={modelProps}
                title={modelAttributeKey}
                renderSubArray
              />
            );
          })}
      </fieldset>,
    ];
  }

  render() {
    const { headerName } = this.props;

    return (
      <div>
        <Row style={{ marginTop: '15px' }}>
          <h3>Detail view of the {headerName}</h3>
        </Row>
        {this.renderFields()}
      </div>
    );
  }
}

export default reduxForm({ validate })(injectIntl(CrudViewForm));
