import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import gql from 'graphql-tag';
import CrudTable from 'components/Crud/CrudTable';
import { graphql, compose } from 'react-apollo';
import moment from 'moment';

import Loading from 'components/Loading';
import ValidationLogsModalButton from './validationLogsModalButton';
import RenewModalButton from './Renew/renewModalButton';
import ApiKeySecretButton from './ApiKeySecretButton';
import messages from './messages';
import baseStyles from '../../styles/app.scss';
import { DB_DATE_FORMAT } from '../../util';

const lastSuccessfulValidationLogQuery = gql`
  query lastSuccessfulValidationLogForApiKey($apiKeyId: String!) {
    lastSuccessfulValidationLogForApiKey(apiKeyId: $apiKeyId) {
      id
      createdAt
    }
  }
`;
class ApiKeyTable extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    validityData: PropTypes.shape({
      apiKeys: PropTypes.arrayOf(
        PropTypes.shape({
          validities: PropTypes.array.isRequired,
        }).isRequired,
      ).isRequired,
      licenses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          duration: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      loading: PropTypes.bool.isRequired,
    }).isRequired,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static async getActiveAndFutureValidities({ validities }) {
    if (!Array.isArray(validities)) return '';
    const now = moment();
    const filteredValidities = validities.filter(
      validity => moment(validity.end, DB_DATE_FORMAT) > now,
    );
    return filteredValidities.map(
      v =>
        `${v.license.name}: ${moment(v.start).format('DD.MM.YYYY')}-${moment(
          v.end,
        ).format('DD.MM.YYYY')}`,
    );
  }

  async getLastSuccessfulValidation(apiKey) {
    const lastSuccessfulValidation = await this.context.client.query({
      query: lastSuccessfulValidationLogQuery,
      variables: {
        apiKeyId: apiKey.id,
      },
      fetchPolicy: 'network-only',
    });
    if (
      lastSuccessfulValidation &&
      lastSuccessfulValidation.data &&
      lastSuccessfulValidation.data.lastSuccessfulValidationLogForApiKey
    ) {
      return lastSuccessfulValidation.data.lastSuccessfulValidationLogForApiKey
        .createdAt;
    }

    return null;
  }

  render() {
    if (this.props.validityData.loading) return <Loading />;
    const modelQueryAttributes = [
      'id',
      'secret',
      'name',
      'description',
      'enabled',
      { license: ['id'] },
      { billingData: ['id', { customer: ['name'] }] },
      { hosts: ['id'] },
      { validities: ['id'] },
    ];
    const modelQueryArguments = {
      attributes: modelQueryAttributes,
    };

    // accumulate all validities and put them as an array in an object with the apiKeyId as key
    let allValidities = {};
    const validityQueryData = this.props.validityData;
    if (validityQueryData && validityQueryData.apiKeys) {
      allValidities = validityQueryData.apiKeys.reduce(
        (allApiKeys, apiKey) => ({
          ...allApiKeys,
          [apiKey.id]: apiKey.validities,
        }),
        {},
      );
    }

    const tableAttributes = {
      id: { width: 200 },
      name: { width: 200 },
      description: { width: 100 },
      billingData: {
        width: 100,
        title: messages.customer,
        queryValue: obj => obj.customer.name,
      },
      enabled: { width: 60 },
      ativeAndFutureValidities: {
        noDbField: true,
        width: 300,
        resolve: apiKey =>
          ApiKeyTable.getActiveAndFutureValidities({
            validities: allValidities[apiKey.id],
          }),
      },
      lastSuccessfulValidation: {
        width: 170,
        resolve: apiKey => this.getLastSuccessfulValidation(apiKey),
      },
    };

    const actionComponents = [
      row => <ValidationLogsModalButton apiKey={row} />,
      row => <ApiKeySecretButton apiKey={row} />,
      row => (
        <RenewModalButton
          apiKey={row}
          validities={allValidities[row.id] ? allValidities[row.id] : []}
          licenses={validityQueryData ? validityQueryData.licenses : []}
        />
      ),
    ];
    return (
      <CrudTable
        headerName="Api Keys"
        modelName="apiKeys"
        modelQueryArguments={modelQueryArguments}
        messages={messages}
        tableAttributes={tableAttributes}
        match={this.props.match}
        actionComponents={actionComponents}
        actionComponentsWidth={500}
      />
    );
  }
}

const allValiditiesQuery = gql`
  query validities {
    apiKeys {
      id
      validities {
        id
        start
        end
        enabled
        license {
          id
          name
        }
      }
    }
    licenses {
      id
      name
      duration
      description
      enabled
      renewable
    }
  }
`;

export default compose(
  graphql(allValiditiesQuery, {
    name: 'validityData',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
  withStyles(baseStyles),
)(ApiKeyTable);
